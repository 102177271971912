import React from "react";
import QuestionTemplate from "./questionnaires/QuestionTemplate";

export default function ThankYou({ companyName, onSubmit }) {
  return (
    <QuestionTemplate
      title={`Congratulations, ${companyName}!`}
      submitText="Close"
      onSubmit={onSubmit}
      showProgress={false}
    >
      <div className="flex flex-col gap-2">
        <p>
          You have successfully completed the process. Expect a text and call
          soon from <span className="font-bold">844-768-2769</span> to finalize
          details.
        </p>
        <p>
          For any questions, feel free to contact us by phone or text at this
          number.
        </p>
        <p>Thank you for your patience. We look forward to assisting you!</p>
      </div>
    </QuestionTemplate>
  );
}
