import React from 'react';
import QuestionTemplate from './QuestionTemplate';

const PositionForm = ({ onSubmit, onGoBack }) => {
    return (
        <QuestionTemplate
            title="Position #1"
            description="This is the main individual or entity applying for the cash loan. They are primarily responsible for repaying the loan and are typically the one whose creditworthiness and financial information are primarily evaluated during the loan application process."
            onGoBack={onGoBack}
        >
            <button
                className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md focus:outline-none"
                onClick={() => onSubmit(true)}
            >
                Continue
            </button>
        </QuestionTemplate>
    );
};

export default PositionForm;
