import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const OwnershipForm = ({ firstName, onSubmit, onGoBack }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        onSubmit(option);
    };

    return (
        <QuestionTemplate
            title={`Great! And Do You Own At Least 51% ${firstName}?`}
            description="We are asking if you hold a 51% ownership stake to understand the level of control and influence you have over company decisions. This information helps us assess how decisions are made and who has the authority to implement changes or direct company strategies."
            onSubmit={() => handleOptionSelect(selectedOption)} // Submit the selected option
            onGoBack={onGoBack}
            hasOptionsButtons={true}
        >
            <div className="flex flex-col gap-4">
                <button
                    className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out ${selectedOption === 'Yes' ? 'bg-blue-700' : ''} focus:outline-none`}
                    onClick={() => handleOptionSelect('Yes')}
                >
                    Yes
                </button>
                <button
                    className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out ${selectedOption === 'No' ? 'bg-blue-700' : ''} focus:outline-none`}
                    onClick={() => handleOptionSelect('No')}
                >
                    No
                </button>
            </div>
        </QuestionTemplate>
    );
};

export default OwnershipForm;
