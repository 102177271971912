import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const ProgressBars = ({ value }) => {
    return (
        <div className="max-w-lg mx-auto px-6 bg-white text-center">
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <BorderLinearProgress variant="determinate" value={value} />
            </Stack>
        </div>
    );
}

export default ProgressBars;
