import React from 'react'
import { BiConversation } from "react-icons/bi";
import { FaRegFileAlt } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";

const data = [
    {id: 1, name: 'Consultation', para: "Our financial specialists will evaluate your business's distinct requirements and identify the ideal financing solution.", icon: <BiConversation />},
    {id: 2, name: 'Application', para: "Send in your application along with the necessary documents, and we'll swiftly review it.", icon: <FaRegFileAlt />},
    {id: 3, name: 'Approval & funding', para: "Upon approval, the funds will be deposited into your account, ready for immediate use in meeting your business requirements.", icon: <TbTargetArrow />},
];

const IconLis = () => {
    return (
        <div className="w-full h-auto">
            <div className="w-[90%] md:w-[75%] lg:w-[69%] mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {data.map((item) => (
                        <div key={item.id} className="p-4 my-8 flex flex-col items-center">
                            <span className="text-[40px] md:text-[50px] lg:text-[65px] text-center">{item.icon}</span>
                            <h3 className="text-[20px] md:text-[22px] lg:text-[25px] text-center font-semibold mt-4">{item.name}</h3>
                            <p className="text-[15px] md:text-[16px] lg:text-[17px] px-2 md:px-4 text-center mt-2">{item.para}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default IconLis;
