import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const LoanBalanceForm = ({ onSubmit, onGoBack }) => {
    const [loanBalance, setLoanBalance] = useState('');
    const [error, setError] = useState('');
    const [dontKnow, setDontKnow] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        setLoanBalance(value);
        if (value.trim() !== '') {
            setError('');
        }
    };

    const handleCheckboxChange = () => {
        setDontKnow(!dontKnow);
        if (!dontKnow) {
            setLoanBalance('');
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!dontKnow && loanBalance.trim() === '') {
            setError('Loan balance is required');
        } else {
            onSubmit(dontKnow ? 'Unknown' : loanBalance);
        }
    };

    return (
        <QuestionTemplate
            title="Amazing! What Is The Loan Balance?"
            description="We need your current loan balance to understand your existing financial commitments accurately."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4 relative">
                <span className="absolute left-3 top-2.5 text-gray-500">$</span>
                <input
                    type="text"
                    name="loanBalance"
                    placeholder="Loan Balance"
                    value={loanBalance}
                    onChange={handleChange}
                    disabled={dontKnow}
                    className={`mt-1 block w-full pl-8 px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
            <div className="mb-4 text-left">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={dontKnow}
                        onChange={handleCheckboxChange}
                        className="form-checkbox"
                    />
                    <span className="ml-2">I Don't Know</span>
                </label>
            </div>
        </QuestionTemplate>
    );
};

export default LoanBalanceForm;
