import React from "react";
import logo from "../../assets/images/Logo.png";
import useSurveyStore from "../../store/useSurveyStore";
import ProgressBars from "../ProgressBars";
import { IoMdClose } from "react-icons/io";

const QuestionTemplate = ({
  title,
  description,
  children,
  onSubmit,
  onGoBack,
  submitText = "Submit and proceed",
  backText = "Go Back",
  hasOptionsButtons = false,
  showProgress = true,
}) => {
  const { reset, percentageProcess } = useSurveyStore();
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 py-4">
      <div className="max-w-xl mx-auto p-6 bg-white text-center relative rounded-lg shadow-lg max-h-full overflow-y-auto flex flex-col w-full">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl"
          onClick={reset}
          title="Close Survey"
        >
          <IoMdClose />
        </button>
        <img
          src={logo}
          alt="CashAdvance USA Logo"
          className="mx-auto mb-4 w-32 h-auto"
        />
        <h1 className="text-2xl font-semibold mb-4">{title}</h1>
        {description && <p className="mb-6 text-gray-700">{description}</p>}
        <div className="overflow-y-auto -mx-6 px-6">{children}</div>
        {!hasOptionsButtons && (
          <div className="mt-4">
            <button
              type="submit"
              onClick={onSubmit}
              className="submit-btn w-full py-2 px-4 text-white rounded-lg shadow-md focus:outline-none"
            >
              {submitText}
            </button>
          </div>
        )}
        {onGoBack && (
          <button
            onClick={onGoBack}
            className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none"
          >
            {backText}
          </button>
        )}
        {showProgress && (
          <div className="mt-6">
            <ProgressBars value={percentageProcess} />
          </div>
        )}
        <div className="mt-4 text-sm text-center">
          <a
            href="https://cashadvance-usa.com/terms-and-conditions-2"
            className="text-gray-600 hover:underline"
          >
            Terms of use
          </a>
          <span className="mx-2">·</span>
          <a
            href="https://cashadvance-usa.com/privacy-policy907306"
            className="text-gray-600 hover:underline"
          >
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuestionTemplate;
