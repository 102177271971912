import React, { useState } from "react";
import QuestionTemplate from "./QuestionTemplate";

const LegalStructureForm = ({ businessName, onSubmit, onGoBack }) => {
  const options = [
    "Corporation",
    "Limited Liability Company",
    "Partnership",
    "Limited Partnership (LP)",
    "Limited Liability Partnership",
    "Sole Proprietorship",
  ];
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSubmit(option);
  };

  return (
    <QuestionTemplate
      title={`Amazing! What's The Legal Structure Of ${businessName}?`}
      description="This question helps us understand the legal structure of your business, which can determine how we can best assist you."
      onGoBack={onGoBack}
      onSubmit={() => handleOptionSelect(selectedOption)} // Passing selectedOption on submit
      hasOptionsButtons={true}
    >
      <div className="flex flex-col gap-4">
        {options.map((option, index) => (
          <button
            key={index}
            className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out ${
              selectedOption === option ? "bg-blue-700" : ""
            } focus:outline-none`}
            onClick={() => handleOptionSelect(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </QuestionTemplate>
  );
};

export default LegalStructureForm;
