import React from "react";
import QuestionTemplate from "./QuestionTemplate";

const FicoScoreForm = ({ onSubmit, onGoBack }) => {
  const options = [
    "Bad (300-500)",
    "Poor (501-600)",
    "Fair (601-659)",
    "Good (660-720)",
    "Excellent (720+)",
  ];

  return (
    <QuestionTemplate
      title="Please Provide Us With Your FICO Score"
      description="A FICO score is a creditworthiness number from 300 to 850, based on factors like payment history and credit usage. Regardless of the FICO score, we can find solutions to still get your business a cash advance."
      onGoBack={onGoBack}
      hasOptionsButtons={true}
    >
      <div className="flex flex-col gap-4">
        {options.map((option, index) => (
          <button
            key={index}
            className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none "
            onClick={() => onSubmit(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </QuestionTemplate>
  );
};

export default FicoScoreForm;
