import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const LenderNamesForm = ({ onSubmit, onGoBack }) => {
    const [lenderNames, setLenderNames] = useState({
        lenderName1: '',
        lenderName2: '',
        lenderName3: '',
        lenderName4: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLenderNames((prevNames) => ({
            ...prevNames,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(lenderNames);
    };

    return (
        <QuestionTemplate
            title="What's The Name Of The Lender?"
            description="We need to know about lender names because it helps us verify the ownership of collateral, assess the associated risk, secure repayment, and ensure compliance with regulations in our cash advance business."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <input
                    type="text"
                    name="lenderName1"
                    placeholder="Lender Name 1"
                    value={lenderNames.lenderName1}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="lenderName2"
                    placeholder="Lender Name 2 (Optional)"
                    value={lenderNames.lenderName2}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="lenderName3"
                    placeholder="Lender Name 3 (Optional)"
                    value={lenderNames.lenderName3}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="lenderName4"
                    placeholder="Lender Name 4 (Optional)"
                    value={lenderNames.lenderName4}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
        </QuestionTemplate>
    );
};

export default LenderNamesForm;
