import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const BusinessNameForm = ({ fundingAmount, onSubmit, onGoBack }) => {
    const [businessName, setBusinessName] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setBusinessName(e.target.value);
        if (e.target.value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (businessName.trim() === '') {
            setError('This is a required field');
        } else {
            onSubmit(businessName);
        }
    };

    return (
        <QuestionTemplate
            title={`Could you please provide the official business name requesting between ${fundingAmount}?`}
            description="The official business name is essential for verifying the legitimacy and legal status of your business, which is crucial for assessing creditworthiness and ensuring compliance with financial regulations."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <input
                    type="text"
                    name="businessName"
                    placeholder="Official Business Name"
                    value={businessName}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
            </div>
            {error && <span className="text-red-500 text-sm">{error}</span>}
        </QuestionTemplate>
    );
};

export default BusinessNameForm;
