import React from "react";
import backgroundImage from "../../assets/images/66323a1337a2465889bbf24b.jpeg";
import CardList from "./CardList";
import SurveyFlow from "../SurveyFlow";
import useSurveyStore from "../../store/useSurveyStore";

const Calltoday = () => {
  const { showSurvey, openSurvey } = useSurveyStore();

  return (
    <>
      <div className="relative">
        {/* Container for background and overlay */}
        <div className="relative h-[600px] sm:h-[600px] md:h-[660px]">
          <div
            className="bg-cover bg-fixed h-full flex flex-col items-center justify-center text-center px-4 sm:px-6 md:px-8"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-0"></div>

            <div className="relative z-10 flex xl:-ml-[50vw] flex-col items-center justify-center h-full">
              <h1 className="text-white text-[28px] sm:text-[36px] md:text-[40px] font-bold max-w-full sm:max-w-[80%] md:max-w-[500px]">
                Let's See If <br /> We Are A <br /> Match
              </h1>
              <button
                onClick={openSurvey}
                className="mt-2 sm:mt-6    md:mt-3 text-lg sm:text-xl md:text-2xl font-bold bg-[#37ca37] rounded-lg text-white py-2 sm:py-2 md:py-2 px-6 sm:px-6 md:px-7"
              >
                Call Today
              </button>
            </div>
          </div>
        </div>

        <div>
          <CardList />
        </div>
      </div>
    </>
  );
};

export default Calltoday;
