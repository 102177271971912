import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const PropertyAcquisitionYearForm = ({ onSubmit, onGoBack, firstName }) => {
    const [yearAcquired, setYearAcquired] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setYearAcquired(e.target.value);
        if (e.target.value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (yearAcquired.trim() === '') {
            setError('Year acquired is required');
        } else {
            onSubmit(yearAcquired);
        }
    };

    return (
        <QuestionTemplate
            title={`${firstName}, What Year Was The Property Acquired?`}
            description="We inquire about when the property was acquired to understand its ownership history and assess its current value accurately, aiding in the evaluation of collateral for your loan application."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <input
                    type="text"
                    name="yearAcquired"
                    placeholder="Year Acquired"
                    value={yearAcquired}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default PropertyAcquisitionYearForm;
