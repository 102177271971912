import React from "react";
import GlobalHeader from "./GlobalHeader";
import GlobalFooter from "./GlobalFooter";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import useSurveyStore from "../../store/useSurveyStore";
import SurveyFlow from "../SurveyFlow";
const Layout = ({ children }) => {
  const { showSurvey } = useSurveyStore();
  return (
    <div>
      {showSurvey && <SurveyFlow />}
      <ToastContainer />
      <GlobalHeader />
      <div className="layout pt-[68px] md:pt-[84px]">{children}</div>
      <GlobalFooter />
    </div>
  );
};

export default Layout;
