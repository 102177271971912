import React from 'react'
import { MdDisplaySettings } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import conver from '../../assets/images/icon/cover.png'
import file from '../../assets/images/icon/file.png'
import target from '../../assets/images/icon/target.png'

const data=[
    {id:1,name:'Manage day-to-day operations',para:"Ensure seamless business operations by covering vital expenses like payroll, rent, and utilities.",icon:<img src={conver} className='className="w-[60-px] h-[60px]'/>},
    {id:2,name:'​Invest in growth opportunities',para:"Capitalize on fresh prospects by allocating funds towards inventory, marketing initiatives, or broadening your range of products or services.",icon:<img src={file} className='className="w-[60-px] h-[60px]'/>},
    {id:3,name:'Overcome cash flow fluctuations',para:"Sail through seasonal or unforeseen revenue fluctuations, ensuring your business stays on course.",icon:<img src={target} className='className="w-[60-px] h-[60px]'/>},
       
]
const IconList = () => {
  return (
    <div className="w-full h-auto p-4">
    <div className="w-full sm:w-[90%] md:w-[69%] mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {data.map((item) => (
          <div key={item.id} className="p-4 my-6">
            <span className="flex justify-center text-[50px] sm:text-[60px]">{item.icon}</span>
            <h3 className="text-[20px] sm:text-[22px] md:text-[25px] text-center font-semibold mt-4">
              {item.name}
            </h3>
            <p className="text-[16px] sm:text-[17px] text-center mt-2 px-4">
              {item.para}
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
  
  )
}

export default IconList
