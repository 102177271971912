import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import GoogleIcon from "@mui/icons-material/Google";

const GlobalFooter = () => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow"></div>
      <footer>
        <div className="w-full bg-[#0D1820] text-white py-6 px-4 sm:py-10 sm:px-6 lg:px-8">
          <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-center items-center gap-4 md:gap-6 text-base md:text-[18px] font-bold">
            <h2 className="w-full text-sm sm:text-base text-center md:text-left flex items-center gap-2">
              <LocationOnIcon className="text-white " />
              Office: 2980 NE 207th St, Aventura, FL 33180
            </h2>
            <span className="w-full text-sm sm:text-base text-center md:text-left flex items-center gap-2">
              <WhatsAppIcon className="text-white" />
              Call (844) 768-2769
            </span>
            <span className="w-full text-sm sm:text-base text-center md:text-left flex items-center gap-2">
              <MailIcon className="text-white" />
              Email: info@cashadvance-usa.com
            </span>
            <p className="w-full text-sm sm:text-base text-center md:text-left flex items-center gap-2">
              <GoogleIcon className="text-white" />
              Site: cashadvance-usa.com
            </p>
          </div>
        </div>

        <div className="w-full p-5 flex flex-col  items-center text-center mt-5">
          <h2 className="text-xl">
            <img src="./Logo.png" className="w-[190px] h-[36px]" alt="Logo" />
          </h2>
          <p className="mt-2 text-xl text-gray-600">
            Privacy Policy | Terms and Conditions | Data Request Page
          </p>
          <p className="mt-2 text-xl  text-gray-600">
            Copyright 2024 @ cashadvance-usa.com. All rights reserved
          </p>
        </div>
      </footer>
    </div>
  );
};

export default GlobalFooter;
