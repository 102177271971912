import React, { useState } from "react";
import QuestionTemplate from "./QuestionTemplate";

const FundUtilizationForm = ({ firstName, onSubmit, onGoBack }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [other, setOther] = useState("");
  const [error, setError] = useState("");

  const options = [
    "Expansion",
    "Equipment purchase",
    "Purchase a vehicle",
    "Inventory",
    "Payroll",
    "Marketing",
    "Commercial real estate",
    "Remodel my location",
    "Refinance debt",
    "Finance Accounts Receivable",
    "Buy a business/franchise",
    "Start a business",
    "Other",
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setError(""); // Clear the error message
    if (option !== "Other") {
      onSubmit(option);
    }
  };

  const handleChange = (e) => {
    setOther(e.target.value);
    setError(""); // Clear the error message
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedOption || other.trim()) {
      onSubmit(selectedOption === "Other" ? other : selectedOption);
    } else {
      setError("Please select an option or type in the field");
    }
  };

  return (
    <QuestionTemplate
      title={`${firstName}, What Would You Like To Use Your Advance On?`}
      description="We ask about fund utilization to tailor our support to your business goals effectively."
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      hasOptionsButtons={true}
    >
      <div className="flex flex-col gap-4 overflow-y-auto">
        {options.map((option, index) => (
          <button
            key={index}
            className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none ${
              selectedOption === option ? "ring-2 ring-blue-300" : ""
            }`}
            onClick={() => handleOptionSelect(option)}
          >
            {option}
          </button>
        ))}
      </div>
      {selectedOption === "Other" && (
        <>
          <p className="mt-4 mb-2">Please Specify</p>
          <input
            type="text"
            name="other"
            placeholder="Fund Utilization"
            value={other}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </>
      )}
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </QuestionTemplate>
  );
};

export default FundUtilizationForm;
