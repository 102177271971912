import React from 'react'
import defaultBackgroundImage from '../assets/images/e1.jpeg'
import ChooseEquipment from '../components/pages/ChooseEquipment'
import FinancialListTwo from '../components/pages/FinancialListTwo'
import Ourpro from '../components/pages/Ourpro'
import GetStarted from '../components/pages/GetStarted'
import Form from '../components/pages/Form'

const EquipmentFinancing = ({
    backgroundImage = defaultBackgroundImage,
    title = "Equipment Financing",
    subtitle = "Elevate Your Business with Equipment Financing",
    description = "In a fiercely competitive business environment, staying ahead requires access to cutting-edge equipment and technology. Our equipment financing options empower you to procure the necessary assets for your business without immobilizing your valuable capital. Invest in your business's future while safeguarding your cash flow.",
    btnText = "GET STARTED"
  }) => {
    return (
    <>
           <div
  className="bg-cover bg-fixed h-[510px] flex items-center justify-center"
  style={{
    backgroundImage: `url(${backgroundImage})`,
  }}
>
  <h1 className="text-white text-[32px] md:text-[42px] -mt-11 font-bold text-center px-4 md:px-0">
 {title}
  <br />
 <p className='text-3xl'> {subtitle}</p>
  </h1>
    
</div>

<div className="bg-white my-7 px-3  md:px-0">
  <div className="text-center md:px-[40px] lg:px-[100px] xl:px-[120px] text-[18px] md:text-[15px] ">
  
 {description} </div>
  <div className="text-center my-4">
    <button className="border bg-[#37CA37] rounded-lg  text-[7px] md:text-[22px] text-white py-3 md:py-2 px-5 md:px-7">
      {btnText}
    </button>
  </div>
</div>
<ChooseEquipment/>
{/* <FinancialListTwo/> */}
<Ourpro/>
<GetStarted/>
<Form/>
    </>
  )
}

export default EquipmentFinancing
