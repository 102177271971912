// src/mockData.js

export const mockUsers = [
    {
        id: 1,
        username: 'admin',
        email: 'admin1@example.com',
        role: 'admin',
        createdAt: '2024-01-01T08:30:00Z',
        password: 'admin',
    },
    {
        id: 2,
        username: 'user1',
        email: 'user1@example.com',
        role: 'user',
        createdAt: '2024-01-02T09:00:00Z',
        password: 'admin',
    },
    // Add more users as needed
];

export const mockSessions = [
    { id: 1, seenAt: '1 second ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 2, seenAt: '2 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 3, seenAt: '6 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 4, seenAt: '6 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 5, seenAt: '7 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 6, seenAt: '14 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 7, seenAt: '14 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 8, seenAt: '15 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 9, seenAt: '16 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 10, seenAt: '17 minutes ago', email: '-', firstSeenAt: 'Monday, August 19, 2024' },
    // Add more mock data as needed
];

export const mockConversions = [
    { id: 1, seenAt: '1 hour ago', email: 'mjon95715@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 2, seenAt: '1 hour ago', email: 'mdrohimbadsharohim257@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 3, seenAt: '2 hours ago', email: 'jeremylehnig9@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 4, seenAt: '3 hours ago', email: 'latoyaclark630@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 5, seenAt: '3 hours ago', email: 'brandymcclain330@gyahoo.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 6, seenAt: '6 hours ago', email: 'jacobiprimas73@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 7, seenAt: '7 hours ago', email: 'patrick@delvehocidesigns.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 8, seenAt: '7 hours ago', email: 'sinthiabason7777@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 9, seenAt: '9 hours ago', email: 'jtay40977@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    { id: 10, seenAt: '11 hours ago', email: 'giaguines04@gmail.com', firstSeenAt: 'Monday, August 19, 2024' },
    // Add more mock data as needed
];

export const questionStatistics = {
    question: "What's your desired funding amount?",
    description: "Kindly provide your desired funding amount. This will help us in tailoring the best financial solutions for your needs. Expect a brief series of questions, which should require approximately 5 minutes of your time. Intended only for business owners.",
    totalResponses: 58129,
    totalAnswered: 4091,
    options: [
        { label: "$5000 - $20,000", percentage: 46, answers: 1866 },
        { label: "$20,000 - $50,000", percentage: 16, answers: 656 },
        { label: "$50,000 - $100,000", percentage: 10, answers: 395 },
        { label: "$100,000 - $250,000", percentage: 10, answers: 394 },
        { label: "$1 Million+", percentage: 8, answers: 322 },
        { label: "$250,000 - $1 Million", percentage: 7, answers: 288 },
        { label: "$1000 - $10,000", percentage: 3, answers: 111 },
        { label: "$10,000 - $100,000", percentage: 1, answers: 41 },
        { label: "$250,000 - $500,000", percentage: 0, answers: 12 },
        { label: "$500,000 +", percentage: 0, answers: 6 },
    ],
};

export const mockLeads = [
    {
        id: 1,
        desiredFunding: "$250,000 - $1 Million",
        officialBusinessName: "Zaid Test 1",
        typeOfBankAccount: "business bank account",
        businessActivity: "More than a year",
        monthlyRevenue: 500000,
        firstName: "Zaid",
        lastName: "Test 1",
        phoneNumberAndEmail: {
            phoneNumber: "3004248574",
            email: "zh9507@gmail.com"
        },
        dateOfBirth: "2000-01-21",
        ficoScore: "700-850",
        socialSecurityNumber: "123432232",
        homeAddress: {
            street: "23",
            unit: "234",
            city: "23",
            state: "AK",
            zipCode: "234"
        },
        utilizationOfFunds: "Payroll",
        legalStructure: "Limited Liability Company",
        owns51Percent: "Yes",
        ownershipPercentage: "2",
        businessInfo: {
            street: "234",
            unit: "234",
            city: "234",
            state: "234",
            zipCode: "234",
            taxId: "234",
            phoneNumber: "2345654323",
            businessStartDate: "2000-01"
        },
        liensOrJudgments: "Yes",
        defaultedCashAdvance: "Yes",
        filedBankruptcy: "Yes",
        currentCashAdvance: "Yes",
        lenderName: "bha",
        currentBalance: "345",
        paymentFrequency: "Bi-Weekly",
        paymentAmount: "345",
        hasCollateral: "Yes",
        position1: true,
        propertyAddress: {
            street: "345",
            city: "345",
            state: "345",
            zipCode: "345",
            country: "345"
        },
        assetType: "Residential",
        yearAcquired: "345",
        purchasePrice: "345",
        currentValue: "345",
        loanBalance: "345",
        otherLenders: "Yes",
        lenderName1: {
            lenderName1: "34",
            lenderName2: "34",
            lenderName3: "34",
            lenderName4: "34"
        },
        titleHolders: {
            titleHolder1: "345",
            titleHolder2: "345",
            titleHolder3: "345",
            titleHolder4: "345"
        },
        createdAt: '2024-01-10T08:30:00Z',
    },
    {
        id: 2,
        desiredFunding: "$50,000 - $100,000",
        officialBusinessName: "Example Business 2",
        typeOfBankAccount: "business bank account",
        businessActivity: "Less than a year",
        monthlyRevenue: 20000,
        firstName: "Jane",
        lastName: "Doe",
        phoneNumberAndEmail: {
            phoneNumber: "1234567890",
            email: "jane.doe@example.com"
        },
        dateOfBirth: "1990-05-15",
        ficoScore: "600-650",
        socialSecurityNumber: "987654321",
        homeAddress: {
            street: "456",
            unit: "Apt 789",
            city: "Smallville",
            state: "TX",
            zipCode: "54321"
        },
        utilizationOfFunds: "Marketing",
        legalStructure: "Corporation",
        owns51Percent: "No",
        ownershipPercentage: "49",
        businessInfo: {
            street: "789",
            unit: "Suite 100",
            city: "Smallville",
            state: "TX",
            zipCode: "54321",
            taxId: "987654321",
            phoneNumber: "0987654321",
            businessStartDate: "2022-06"
        },
        liensOrJudgments: "No",
        defaultedCashAdvance: "No",
        filedBankruptcy: "No",
        currentCashAdvance: "No",
        lenderName: "",
        currentBalance: "",
        paymentFrequency: "",
        paymentAmount: "",
        hasCollateral: "No",
        position1: false,
        propertyAddress: {},
        assetType: "",
        yearAcquired: "",
        purchasePrice: "",
        currentValue: "",
        loanBalance: "",
        otherLenders: "No",
        lenderName1: {},
        titleHolders: {},
        createdAt: '2024-01-15T12:00:00Z',
    },
    // Add more leads as needed
];
