import React from "react";
import QuestionTemplate from "./QuestionTemplate";

const CurrentCashAdvanceForm = ({ onSubmit, onGoBack, firstName }) => {
  return (
    <QuestionTemplate
      title={`${firstName}, You Are Doing Fantastic! Do You Currently Have A Cash Advance?`}
      description="We ask if you have an existing cash advance to ensure responsible lending practices and to customize our offerings to your financial situation."
      onSubmit={(e) => e.preventDefault()} // Prevent form submission since we're handling clicks directly
      onGoBack={onGoBack}
      hasOptionsButtons
    >
      <div className="flex flex-col gap-4">
        <button
          type="button"
          className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
          onClick={() => onSubmit("Yes")}
        >
          Yes
        </button>
        <button
          type="button"
          className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
          onClick={() => onSubmit("No")}
        >
          No
        </button>
      </div>
    </QuestionTemplate>
  );
};

export default CurrentCashAdvanceForm;
