import React from 'react';
import QuestionTemplate from './QuestionTemplate';

const BankAccountTypeForm = ({ companyName, onSubmit, onGoBack }) => {
    const handleSelect = (type) => {
        onSubmit(type); // Directly call onSubmit with the selected option
    };

    return (
        <QuestionTemplate
            title={`What type of bank account does ${companyName} have?`}
            description="We ask this to determine our ability to fund your business. It is crucial that you have an active business bank account that has been operational for the past four months."
            onGoBack={onGoBack}
            hasOptionsButtons={true}
        >
            <p className="mb-6 font-semibold text-lg text-center">
                We do not accept Startups
            </p>
            <div className="flex justify-center space-x-4 mb-4">
                <button
                    onClick={() => handleSelect('business bank account')}
                    className="submit-btn text-white py-4 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
                >
                    Business Bank Account
                </button>
                <button
                    onClick={() => handleSelect('personal bank account')}
                    className="submit-btn text-white py-4 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
                >
                    Personal Bank Account
                </button>
            </div>
        </QuestionTemplate>
    );
};

export default BankAccountTypeForm;
