import React, { useState } from "react";
import QuestionTemplate from "./QuestionTemplate";

const TitleHoldersForm = ({ onSubmit, onGoBack, onFinalSubmit }) => {
  const [titleHolders, setTitleHolders] = useState({
    titleHolder1: "",
    titleHolder2: "",
    titleHolder3: "",
    titleHolder4: "",
  });
  const [dontHaveTitleHolders, setDontHaveTitleHolders] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTitleHolders((prevHolders) => ({
      ...prevHolders,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setDontHaveTitleHolders(!dontHaveTitleHolders);
    if (!dontHaveTitleHolders) {
      setTitleHolders({
        titleHolder1: "",
        titleHolder2: "",
        titleHolder3: "",
        titleHolder4: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(dontHaveTitleHolders ? "None" : titleHolders);
    requestAnimationFrame(() => {
      onFinalSubmit?.();
    });
  };

  return (
    <QuestionTemplate
      title="Great, And What About Title Holders?"
      description="We need to know about title holders because it helps us verify the ownership of collateral, assess the associated risk, secure repayment, and ensure compliance with regulations in our cash advance business."
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
    >
      <div className="mb-4">
        <input
          type="text"
          name="titleHolder1"
          placeholder="Title Holder 1"
          value={titleHolders.titleHolder1}
          onChange={handleChange}
          disabled={dontHaveTitleHolders}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <input
          type="text"
          name="titleHolder2"
          placeholder="Title Holder 2 (Optional)"
          value={titleHolders.titleHolder2}
          onChange={handleChange}
          disabled={dontHaveTitleHolders}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <input
          type="text"
          name="titleHolder3"
          placeholder="Title Holder 3 (Optional)"
          value={titleHolders.titleHolder3}
          onChange={handleChange}
          disabled={dontHaveTitleHolders}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <input
          type="text"
          name="titleHolder4"
          placeholder="Title Holder 4 (Optional)"
          value={titleHolders.titleHolder4}
          onChange={handleChange}
          disabled={dontHaveTitleHolders}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="mb-4 text-left">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={dontHaveTitleHolders}
            onChange={handleCheckboxChange}
            className="form-checkbox"
          />
          <span className="ml-2">I Don't Have Title Holders</span>
        </label>
      </div>
    </QuestionTemplate>
  );
};

export default TitleHoldersForm;
