import React from 'react';
import QuestionTemplate from './QuestionTemplate';

const MonthlyRevenueForm = ({ businessName, onSubmit, onGoBack }) => {
    const options = [
        { label: '$5,000 - $10,000', value: 10000 },
        { label: '$10,000 - $15,000', value: 15000 },
        { label: '$15,000 - $25,000', value: 25000 },
        { label: '$25,000 - $40,000', value: 40000 },
        { label: '$40,000 - $60,000', value: 60000 },
        { label: '$60,000 - $100,000', value: 100000 },
        { label: '$100,000 - $250,000', value: 250000 },
        { label: '$250,000 - $500,000', value: 500000 },
        { label: '$500,000+', value: 500001 },  // Assuming $500,000+ means anything above $500,000
    ];

    return (
        <QuestionTemplate
            title={`What's ${businessName}'s Monthly Revenue?`}
            description="To better assess the funding amount suitable for your business, please provide your business's approximate monthly revenue. This information helps us tailor our funding offerings to best meet your needs."
            onGoBack={onGoBack}
            hasOptionsButtons={true}
        >
            <div className="flex flex-col gap-4">
                {options.map((option, index) => (
                    <button
                        key={index}
                        className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none "
                        onClick={() => onSubmit(option.value)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </QuestionTemplate>
    );
};

export default MonthlyRevenueForm;
