import React from "react";
import SurveyFlow from "../SurveyFlow";
import useSurveyStore from "../../store/useSurveyStore";
const GetStarted = () => {
  const { showSurvey, openSurvey } = useSurveyStore();
  return (
    <>
      <div className="bg-[#F3FCF4] py-6 sm:py-9 px-4 sm:px-8 lg:px-[15rem]">
        <div className="w-full max-w-[1200px] mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            <div className="mt-6 md:mt-11">
              <h1 className="text-3xl sm:text-4xl md:text-[49px] font-bold">
                Get Started with <br /> CashAdvanceUsa
              </h1>
              <p className="text-base sm:text-lg py-4 md:text-xl mt-2">
                Ready to unleash your business's full potential? Reach out to
                our team of financial experts today to explore your distinctive
                requirements and experience the CashAdvanceUsa advantage.
              </p>
              <div className="my-4 sm:my-5">
                <button
                  onClick={openSurvey}
                  className="border text-lg sm:text-xl md:text-[23px] font-bold bg-[#37ca37] py-3 sm:py-4 md:py-4 px-5 sm:px-6 md:px-7 rounded-md text-white"
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <img
                src="pic3.jpg"
                className="w-full h-auto max-w-full rounded-md"
                alt="CashAdvanceUsa"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
