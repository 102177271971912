import React from 'react';
import finc from '../../assets/images/icon/finc.png';
import bulb from '../../assets/images/icon/bulb.png';
import flax from '../../assets/images/icon/overcome.png';
import tax from '../../assets/images/icon/tax.png';

const WhyChIcon = () => {
    const data = [
        { id: 1, name: 'Finance new or used equipment', para: "Get essential tools, machinery, or technology for your business with our equipment financing, ensuring competitiveness and fostering growth.", icon: <img src={finc} className='w-[60px] h-[60px]' alt="Finance" /> },
        { id: 2, name: 'Preserve working capital', para: "Preserve your cash flow by financing your equipment acquisitions, liberating funds for other critical business requirements.", icon: <img src={bulb} className='w-[60px] h-[60px]' alt="Preserve" /> },
        { id: 3, name: 'Flexible financing terms', para: "Select from a variety of financing terms tailored to match your budget and business objectives.", icon: <img src={flax} className='w-[60px] h-[60px]' alt="Flexible" /> },
        { id: 4, name: 'Potential tax advantages', para: "Subject to your circumstances, equipment financing could yield tax advantages. Seek advice from your financial advisor for further details.", icon: <img src={tax} className='w-[60px] h-[60px]' alt="Tax" /> },
    ];

    return (
        <div className='w-full'>
            <div className='w-[90%] md:w-[80%] lg:w-[69%] mx-auto'>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
                    {data.map((item) => (
                        <div key={item.id} className="flex flex-col items-center my-6 sm:my-8">
                            <span className='flex justify-center text-[40px] sm:text-[50px] lg:text-[60px]'>{item.icon}</span>
                            <h3 className="text-[16px] sm:text-[18px] lg:text-[19px] text-center font-bold mt-4">{item.name}</h3>
                            <p className="text-[14px] sm:text-[15px] lg:text-[17px] text-center mt-2 px-4">{item.para}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhyChIcon;
