import React from "react";

import QuestionTemplate from "./QuestionTemplate";

const FundingQuestion = ({ onSelect }) => {
  const options = [
    "$5,000 - $20,000",
    "$20,000 - $50,000",
    "$50,000 - $100,000",
    "$100,000 - $250,000",
    "$250,000 - $1 Million",
    "$1 Million+",
  ];

  return (
    <QuestionTemplate
      title="Let's Get You Started On Your Cash Advance Request For Your Business! What Is Your Desired Funding Amount?"
      description={
        <>
          Kindly provide your desired funding amount. This will help us in
          tailoring the best financial solutions for your needs. Expect a brief
          series of questions, which should require approximately 5 minutes of
          your time.{" "}
          <span className="font-bold">Intended only for business owners.</span>
        </>
      }
      hasOptionsButtons={true}
    >
      <div className="flex flex-col gap-4">
        {options.map((option, index) => (
          <button
            key={index}
            type="button"
            className="submit-btn text-white py-3 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
            onClick={() => onSelect(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </QuestionTemplate>
  );
};

export default FundingQuestion;
