import React, { useEffect, useState } from "react";
import QuestionTemplate from "./questionnaires/QuestionTemplate";

export default function LoadingScreen({
  businessName,
  financingReason,
  onSubmit,
}) {
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowLoadingScreen(false);
    }, 5000);
  }, []);
  if (showLoadingScreen) {
    return (
      <QuestionTemplate title={"Analyzing Information..."} hasOptionsButtons>
        <div className="flex flex-col items-center justify-center gap-1 min-h-80 w-full">
          <div role="status" aria-label="loading">
            <svg
              class="w-8 h-8 stroke-[#027BC2] animate-spin"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_9023_61563)">
                <path
                  d="M14.6437 2.05426C11.9803 1.2966 9.01686 1.64245 6.50315 3.25548C1.85499 6.23817 0.504864 12.4242 3.48756 17.0724C6.47025 21.7205 12.6563 23.0706 17.3044 20.088C20.4971 18.0393 22.1338 14.4793 21.8792 10.9444"
                  stroke="stroke-current"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  class="my-path"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_9023_61563">
                  <rect width="24" height="24" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <div className="text-lg">Processing...</div>
        </div>
      </QuestionTemplate>
    );
  }

  return (
    <QuestionTemplate
      title={`Great News, ${businessName}!`}
      submitText="Complete the Survey Now"
      onSubmit={onSubmit}
    >
      <div className="flex flex-col gap-4 mb-4">
        <p>
          We’ve found a few excellent financing options that could be ideal for
          your need for <span className="font-bold">{financingReason}</span>.
        </p>
        <p>
          To finalize the best option for you, we’re just missing some details.{" "}
          <span className="font-bold">
            Please answer the final questions in the rest of the survey
          </span>{" "}
          so we can complete your application and secure the best capital
          solution for your business.
        </p>
      </div>
    </QuestionTemplate>
  );
}
