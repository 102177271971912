import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import useSurveyStore from "../../store/useSurveyStore";
const data = [
  {
    id: 1,
    name: "Fast Results",
    para: "Completing your application only requires 5-10 minutes, and you can expect offers within just a few hours.",
    btn: "Apply Now",
    icon: <FaLongArrowAltRight className="mt-1" />,
  },
  {
    id: 2,
    name: "Flexible Terms",
    para: "We aid you in effortlessly comparing your options and consistently strive to secure the most favorable terms for you.",
    btn: "Apply Now",
    icon: <FaLongArrowAltRight className="mt-1" />,
  },
  {
    id: 3,
    name: "Expert Support",
    para: "Our advisors will ensure that the product you ve selected aligns perfectly with your business requirements.",
    btn: "Apply Now",
    icon: <FaLongArrowAltRight className="mt-1" />,
  },
];

const Card = () => {
  const { showSurvey, openSurvey } = useSurveyStore();
  return (
    <div className="flex flex-wrap items-center  my-9 justify-center gap-5 md:gap-7">
      {data.map((item) => (
        <div
          key={item.id}
          className="card h-[40vh] w-[90%] sm:w-[42vh] md:w-[46vh] bg-[#027BC3] p-5 sm:p-4 rounded-lg shadow-lg text-center"
        >
          <h1 className="text-[22px] sm:text-[24px] md:text-[27px] text-white font-bold mb-3 sm:mb-4 mt-2">
            {item.name}
          </h1>
          <p className="text-white text-[18px] sm:text-[20px] p-4 sm:p-5">
            {item.para}
          </p>
          <button
            onClick={openSurvey}
            className="text-white font-bold px-3 py-2 sm:px-4 sm:py-2 rounded text-[16px] sm:text-[18px] md:text-[19px] flex items-center mx-auto  gap-2 mt-5"
          >
            {item.btn}
            {item.icon}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Card;
