import React from 'react';
import defaultBackgroundImage from '../../assets/images/e2.jpeg';
import WhyChIcon from './WhyChIcon';

const ChooseEquipment = ({
  backgroundImage = defaultBackgroundImage,
  title = 'Why Choose Equipment Financing?',
  description = 'Equipment financing provides a variety of advantages to keep your business ahead:'
}) => {
  return (
    <>
      <div
        className="relative bg-cover bg-fixed h-[380px] flex items-center justify-center bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
      
        <div className="absolute inset-0 bg-black opacity-60"></div>

        <h1 className="relative z-10 text-white text-[20px] md:text-[36px] -mt-11 font-bold text-center px-4 md:px-0">
          {title}
        </h1>
      </div>

      <div className="bg-white my-7 px-3 md:px-0">
        <div className="text-center md:px-[40px] my-11 lg:px-[100px] xl:px-[106px] text-[18px] md:text-[26px]">
          {description}
        </div>
        <WhyChIcon />
      </div>
    </>
  );
};

export default ChooseEquipment;
