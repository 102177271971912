import React from 'react';
import { FaLongArrowAltRight } from "react-icons/fa";

const data = [
  { id: 1, name: 'Fast Results', para: 'Completing your application only requires 5-10 minutes, and you can expect offers within just a few hours.', btn: 'Apply Now',icon:<FaLongArrowAltRight/> },
  { id: 2, name: 'Flexible Terms', para: 'We aid you in effortlessly comparing your options and consistently strive to secure the most favorable terms for you.', btn: 'Apply Now',icon:<FaLongArrowAltRight/> },
  { id: 3, name: 'Expert Support', para: 'Our advisors will ensure that the product you ve selected aligns perfectly with your business requirements.', btn: 'Apply Now',icon:<FaLongArrowAltRight/> },
];

const Cardtwo = () => {
  return (
    <div className="flex flex-wrap items-center  my-9 justify-center gap-6 ">
      {data.map((item) => (
        <div key={item.id} className="card max-h-full bg-[#027BC3]  p-9 rounded-lg shadow-lg max-w-sm text-center">
       
          <h1 className="text-2xl text-white font-bold mb-4">{item.name}</h1>
          <p className="text-white text-lg mb-6">{item.para}</p>
          <button className=" text-white px-4 py-2 rounded ">
            {item.btn}
          </button>
          
        </div>
      ))}
    </div>
  );
};

export default Cardtwo;
