import React from 'react';
import backgroundImage from '../../assets/images/66310b8efeb8e043bed341ff.jpeg'; 
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const MerchantCash = () => {
  return (
    <>
      <div className="relative">
      
        <div
          className="bg-cover bg-fixed h-[400px] sm:h-[430px] flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          {/* Black Overlay */}
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-0"></div>

          {/* Heading */}
          <h1 className="text-white text-[32px] sm:text-[38px] md:text-[41px] -mt-8 sm:-mt-10 font-bold text-center px-4 z-10 relative">
            Merchant Cash Advance
          </h1>
        </div>

        {/* Content Section */}
        <div className="bg-white p-6 sm:p-8 flex justify-center my-6 sm:my-7">
          <div className="text-center font-semibold text-[18px] sm:text-[18px] md:text-[18px] p-3 text-gray-700 space-y-4 max-w-[90%] sm:max-w-[80rem]">
            <p>
              MCAs (Merchant Cash Advances) represent a form of business funding structured similarly to loans, yet distinct from traditional
              banking and government financing. This approach involves acquiring future receivables at a predetermined rate. Typically,
              repayment occurs over a set duration, often spanning several months. In contrast, a loan involves the provision of money to a
              borrower with an associated interest rate, payable over a longer timeframe with defined terms, occasionally extending over several
              years.
            </p>

            <p className="text-[16px] flex justify-center items-center p-3 ">
              <MdKeyboardDoubleArrowRight className="mr-2 text-green-600 text-[28px] sm:text-[16px]" />
              Call us for further details, or submit an application online.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantCash;
