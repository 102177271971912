import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ activeTab }) => {
    return (
        <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold">{activeTab}</h2> {/* This now shows the current tab name */}
            <div className="flex items-center space-x-8">
                <Tabs activeTab={activeTab} />
                <DatePicker />
            </div>
        </div>
    );
};

const Tabs = ({ activeTab }) => {
    const tabs = [
        { name: 'Dashboard', path: '/admin/dashboard' },
        { name: 'Analytics', path: '/admin/analytics/dashboard' },
        { name: 'Insights', path: '/admin/analytics/insights' },
        { name: 'Conversions', path: '/admin/analytics/conversions' },
        { name: 'Sessions', path: '/admin/analytics/sessions' },
    ];

    return (
        <div className="space-x-6">
            {tabs.map((tab) => (
                <Link
                    key={tab.name}
                    to={tab.path}
                    className={`font-semibold ${activeTab === tab.name ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                >
                    {tab.name}
                </Link>
            ))}
        </div>
    );
};

const DatePicker = () => {
    const [dateRange, setDateRange] = React.useState('May 10 - Aug 19');

    return (
        <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2"
        >
            <option value="May 10 - Aug 19">May 10 - Aug 19</option>
            <option value="Today">Today</option>
            <option value="Since Yesterday">Since Yesterday</option>
            <option value="Last 7 Days">Last 7 Days</option>
            <option value="Last 30 Days">Last 30 Days</option>
            <option value="Last 3 Months">Last 3 Months</option>
            <option value="Last 6 Months">Last 6 Months</option>
            <option value="Custom Date">Custom Date</option>
        </select>
    );
};

export default Header;
