import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import MenuIcon from "@mui/icons-material/Menu";

const GlobalHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="w-full py-4 border mx-auto fixed top-0 right-0 left-0 z-[155] bg-white">
      <div className="container mx-auto flex justify-between items-center px-4">
        <div>
          <Link to="/">
            <img
              src="/Logo.png"
              className="w-[200px] cursor-pointer"
              alt="logo"
            />
          </Link>
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-700 focus:outline-none relative"
          >
            <MenuIcon />
          </button>
          {/* Dropdown Menu */}
          <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <nav className="hidden md:flex md:items-center md:gap-6">
          <ul className="flex flex-col md:flex-row p-3 gap-1 md:gap-[70px] mt-5 md:mt-0">
            <li className="text-[17px] hover:text-green-500 cursor-pointer">
              <Link to="/about">About Us</Link>
            </li>
            <li className="relative group text-[17px] hover:text-green-500 cursor-pointer flex items-center gap-1">
              Services
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <ul className="absolute -left-6 top-4 mt-2 hidden group-hover:block bg-white shadow-xl rounded-md w-56 p-2">
                <li className="px-1 py-1 hover:bg-gray-200 text-black cursor-pointer">
                  <Link to="/financial">Financial Streamlining</Link>
                </li>
                <li className="px-1 py-1 hover:bg-gray-200 text-black cursor-pointer">
                  <Link to="/equipment">Equipment Financing</Link>
                </li>
                <li className="px-1 py-1 hover:bg-gray-200 text-black cursor-pointer">
                  <Link to="/merchant">Merchant Cash Advance</Link>
                </li>
              </ul>
            </li>
            <li className="text-[17px] hover:text-green-500 cursor-pointer">
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default GlobalHeader;
