// src/components/SurveyFlow.js
import React, { useEffect, useRef } from "react";
import FundingQuestion from "./questionnaires/FundingQuestion.jsx";
import BusinessNameForm from "./questionnaires/BusinessNameForm.jsx";
import BankAccountTypeForm from "./questionnaires/BankAccountTypeForm.jsx";
import BusinessActiveForm from "./questionnaires/BusinessActiveForm.jsx";
import FirstNameForm from "./questionnaires/FirstNameForm.jsx";
import LastNameForm from "./questionnaires/LastNameForm.jsx";
import MonthlyRevenueForm from "./questionnaires/MonthlyRevenueForm.jsx";
import ContactForm from "./questionnaires/ContactForm.jsx";
import DateOfBirthForm from "./questionnaires/DateOfBirthForm.jsx";
import FicoScoreForm from "./questionnaires/FicoScoreForm.jsx";
import SsnForm from "./questionnaires/SsnForm.jsx";
import PersonalInfoForm from "./questionnaires/PersonalInfoForm.jsx";
import FundUtilizationForm from "./questionnaires/FundUtilizationForm.jsx";
import LegalStructureForm from "./questionnaires/LegalStructureForm.jsx";
import OwnershipForm from "./questionnaires/OwnershipForm.jsx";
import OwnershipPercentageForm from "./questionnaires/OwnershipPercentageForm.jsx";
import CompanyInfoForm from "./questionnaires/CompanyInfoForm.jsx";
import LiensJudgmentsForm from "./questionnaires/LiensJudgmentsForm.jsx";
import DefaultCashAdvanceForm from "./questionnaires/DefaultCashAdvanceForm.jsx";
import BankruptcyForm from "./questionnaires/BankruptcyForm.jsx";
import CurrentCashAdvanceForm from "./questionnaires/CurrentCashAdvanceForm.jsx";
import LenderNameForm from "./questionnaires/LenderNameForm.jsx";
import CurrentBalanceForm from "./questionnaires/CurrentBalanceForm.jsx";
import PaymentFrequencyForm from "./questionnaires/PaymentFrequencyForm.jsx";
import PaymentAmountForm from "./questionnaires/PaymentAmountForm.jsx";
import CollateralForm from "./questionnaires/CollateralForm.jsx";
import PositionForm from "./questionnaires/PositionForm.jsx";
import PropertyAddressForm from "./questionnaires/PropertyAddressForm.jsx";
import AssetTypeForm from "./questionnaires/AssetTypeForm.jsx";
import PropertyAcquisitionYearForm from "./questionnaires/PropertyAcquisitionYearForm.jsx";
import PurchasePriceForm from "./questionnaires/PurchasePriceForm.jsx";
import PropertyValueForm from "./questionnaires/PropertyValueForm.jsx";
import LoanBalanceForm from "./questionnaires/LoanBalanceForm.jsx";
import OtherLendersForm from "./questionnaires/OtherLendersForm.jsx";
import LenderNamesForm from "./questionnaires/LenderNamesForm.jsx";
import TitleHoldersForm from "./questionnaires/TitleHoldersForm.jsx";
import BankStatementUpload from "./questionnaires/BankStatementUpload.jsx";
import useFormState from "../hooks/useFormState.jsx";
import { useLocation } from "react-router-dom";
import useSurveyStore from "../store/useSurveyStore.js";
import { toast } from "react-toastify";
import IndustryForm from "./questionnaires/IndustryForm.jsx";
import useLoadingScreen from "../store/useLoadingScreen.js";
import LoadingScreen from "./LoadingScreen.jsx";
import ThankYou from "./ThankYou.jsx";

const SurveyFlow = () => {
  const [state, updateState] = useFormState();
  const {
    percentageProcess,
    updatePercentageProcess,
    finishedSurvey,
    setFinishedSurvey,
    reset,
  } = useSurveyStore();
  const { showLoadingScreen, openLoadingScreen, closeLoadingScreen } =
    useLoadingScreen();
  // const [isQualifiedLead, setIsQualifiedLead] = useState(null);
  // const QualifiedLeadURL =
  //   "https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/03e84bf7-234d-42a4-91d3-2a64e9b1de0f";
  // const UnQualifiedLeadURL =
  //   "https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/9d07c39b-b44e-4395-a99d-8b7402f5516d";

  const location = useLocation();
  const prevUtmContent = useRef(null);
  const prevUtmMedium = useRef(null);

  useEffect(() => {
    // disable body scroll
    document.body.style.overflow = "hidden";
    return () => {
      // enable body scroll
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmContent = params.get("utm_content");
    const utmMedium = params.get("utm_medium");

    // Update state if utm_content has changed and is valid
    if (utmContent && utmContent !== prevUtmContent.current) {
      console.log("utmContent", utmContent);
      updateState("utmContent")(utmContent); // Assuming updateState handles this
      prevUtmContent.current = utmContent;  // Store current value
    }

    // Update state if utm_medium has changed and is valid
    if (utmMedium && utmMedium !== prevUtmMedium.current) {
      console.log("utmMedium", utmMedium);
      updateState("utmMedium")(utmMedium);  // Assuming updateState handles this
      prevUtmMedium.current = utmMedium;  // Store current value
    }

  }, [location, updateState]);  // Adding updateState as a dependency


  useEffect(() => {
    updatePercentageProcess(state);
  }, [state, updatePercentageProcess]);

  // Determine Qualification
  // const determineQualification = useCallback(() => {
  //   const isQualified =
  //     state.typeOfBankAccount === "business bank account" &&
  //     state.businessActivity === "More than a year" &&
  //     state.monthlyRevenue >= 15000;
  //   setIsQualifiedLead(isQualified);
  // }, [state.typeOfBankAccount, state.businessActivity, state.monthlyRevenue]);

  // useEffect(() => {
  //   determineQualification();

  //   // if (isQualifiedLead !== null && state.phoneNumberAndEmail) {
  //   //     const scriptBaseURL = "https://enessedleasant.com/conversion.js";
  //   //     const srcParams = isQualifiedLead
  //   //         ? "?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=Approved_lead"
  //   //         : "?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=lead";

  //   //     // Inject Google Tag Manager (noscript) iframe for qualified leads
  //   //     if (isQualifiedLead) {
  //   //         const noscript = document.createElement('noscript');
  //   //         const iframe = document.createElement('iframe');
  //   //         iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-M49SBV9Q";
  //   //         iframe.height = "0";
  //   //         iframe.width = "0";
  //   //         iframe.style.display = "none";
  //   //         iframe.style.visibility = "hidden";
  //   //         noscript.appendChild(iframe);
  //   //         document.body.appendChild(noscript);
  //   //     }

  //   //     // Inject custom tracking script
  //   //     const script = document.createElement('script');
  //   //     script.src = scriptBaseURL + srcParams;
  //   //     script.async = true;

  //   //     const insertScript = () => {
  //   //         const match = document.cookie.match(/(^| )vl-cid=([^;]+)/);
  //   //         const cid = match ? match.pop() : localStorage.getItem("vl-cid-expires") && +localStorage.getItem("vl-cid-expires") > (new Date()).getTime() ? localStorage.getItem("vl-cid") : null;
  //   //         if (cid) {
  //   //             script.src = script.src.replace(/cid=.*?(&|$)/, `cid=${cid}&`);
  //   //         }
  //   //         document.head.appendChild(script);
  //   //     };

  //   //     insertScript();
  //   // }
  // }, [state, isQualifiedLead]);

  const handleSubmit = async () => {
    // const url = isQualifiedLead ? QualifiedLeadURL : UnQualifiedLeadURL;
    // const { closeSurvey } = useSurveyStore.getState();
    // closeSurvey();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/answer/cash-advance/go-high-level`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      toast.success("Your data has been submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred while submitting your data.");
    }
  };

  // useEffect(() => {
  //   if (
  //     state.phoneNumberAndEmail ||
  //     state.currentCashAdvance ||
  //     state.titleHolders
  //   ) {
  //     handleSubmit(state);
  //   }
  // }, [state.phoneNumberAndEmail, state.currentCashAdvance, state.titleHolders]);

  const renderCurrentForm = () => {
    // Step 1
    if (!state.desiredFunding)
      return <FundingQuestion onSelect={updateState("desiredFunding")} />;

    // Step 2
    if (!state.officialBusinessName)
      return (
        <BusinessNameForm
          fundingAmount={state.desiredFunding}
          onSubmit={updateState("officialBusinessName")}
          onGoBack={() => updateState("desiredFunding")(null)}
        />
      );

    // Step 3
    if (!state.legalStructure)
      return (
        <LegalStructureForm
          businessName={state.officialBusinessName}
          onSubmit={updateState("legalStructure")}
          onGoBack={() => updateState("officialBusinessName")(null)}
        />
      );

    // Step 4
    if (!state.typeOfBankAccount)
      return (
        <BankAccountTypeForm
          companyName={state.officialBusinessName}
          onSubmit={updateState("typeOfBankAccount")}
          onGoBack={() => updateState("legalStructure")(null)}
        />
      );

    // Step 5
    if (!state.businessActivity)
      return (
        <BusinessActiveForm
          businessName={state.officialBusinessName}
          onSubmit={updateState("businessActivity")}
          onGoBack={() => updateState("typeOfBankAccount")(null)}
        />
      );

    // Step 6
    if (!state.firstName)
      return (
        <FirstNameForm
          businessName={state.officialBusinessName}
          onSubmit={updateState("firstName")}
          onGoBack={() => updateState("businessActivity")(null)}
        />
      );

    // Step 7
    if (!state.lastName)
      return (
        <LastNameForm
          firstName={state.firstName}
          onSubmit={updateState("lastName")}
          onGoBack={() => updateState("firstName")(null)}
        />
      );

    // Step 8
    if (!state.utilizationOfFunds)
      return (
        <FundUtilizationForm
          firstName={state.firstName}
          onSubmit={updateState("utilizationOfFunds")}
          onGoBack={() => updateState("firstName")(null)}
        />
      );

    // Step 9
    if (!state.monthlyRevenue)
      return (
        <MonthlyRevenueForm
          businessName={state.officialBusinessName}
          onSubmit={updateState("monthlyRevenue")}
          onGoBack={() => updateState("utilizationOfFunds")(null)}
        />
      );

    // Step 10
    if (!state.ficoScore)
      return (
        <FicoScoreForm
          firstName={state.firstName}
          onSubmit={updateState("ficoScore")}
          onGoBack={() => updateState("monthlyRevenue")(null)}
        />
      );

    // Step 11
    if (!state.industry)
      return (
        <IndustryForm
          onSubmit={updateState("industry")}
          onGoBack={() => updateState("ficoScore")(null)}
        />
      );

    // Step 12
    if (!state.phoneNumberAndEmail)
      return (
        <ContactForm
          firstName={state.firstName}
          onSubmit={(values) => {
            updateState("phoneNumberAndEmail")(values);
            openLoadingScreen();
          }}
          onGoBack={() => updateState("industry")(null)}
        />
      );

    if (showLoadingScreen) {
      return (
        <LoadingScreen
          businessName={state.officialBusinessName}
          financingReason={state.utilizationOfFunds}
          onSubmit={closeLoadingScreen}
        />
      );
    }

    // Step 13
    if (!state.bankStatements)
      return (
        <BankStatementUpload
          onSubmit={updateState("bankStatements")}
          onGoBack={() => updateState("phoneNumberAndEmail")(null)}
          email={state.phoneNumberAndEmail.email}
        />
      );

    // Step 14
    if (!state.dateOfBirth)
      return (
        <DateOfBirthForm
          firstName={state.firstName}
          onSubmit={updateState("dateOfBirth")}
        />
      );

    // Step 15
    if (!state.socialSecurityNumber)
      return (
        <SsnForm
          firstName={state.firstName}
          onSubmit={updateState("socialSecurityNumber")}
          onGoBack={() => updateState("dateOfBirth")(null)}
        />
      );

    // Step 16
    if (!state.homeAddress)
      return (
        <PersonalInfoForm
          firstName={state.firstName}
          onSubmit={updateState("homeAddress")}
          onGoBack={() => updateState("socialSecurityNumber")(null)}
        />
      );

    // Step 17
    if (state.owns51Percent === null)
      return (
        <OwnershipForm
          firstName={state.firstName}
          onSubmit={updateState("owns51Percent")}
          onGoBack={() => updateState("homeAddress")(null)}
        />
      );

    // Step 18
    if (state.owns51Percent !== null && state.ownershipPercentage === null)
      return (
        <OwnershipPercentageForm
          firstName={state.firstName}
          onSubmit={updateState("ownershipPercentage")}
          onGoBack={() => updateState("owns51Percent")(null)}
        />
      );

    // Step 19
    if (!state.businessInfo)
      return (
        <CompanyInfoForm
          businessName={state.officialBusinessName}
          onSubmit={updateState("businessInfo")}
          onGoBack={() => updateState("ownershipPercentage")(null)}
        />
      );

    // Step 20
    if (!state.liensOrJudgments)
      return (
        <LiensJudgmentsForm
          businessName={state.officialBusinessName}
          onSubmit={updateState("liensOrJudgments")}
          onGoBack={() => updateState("businessInfo")(null)}
        />
      );

    // Step 21
    if (!state.defaultedCashAdvance)
      return (
        <DefaultCashAdvanceForm
          onSubmit={updateState("defaultedCashAdvance")}
          onGoBack={() => updateState("liensOrJudgments")(null)}
        />
      );

    // Step 22
    if (!state.filedBankruptcy)
      return (
        <BankruptcyForm
          onSubmit={updateState("filedBankruptcy")}
          onGoBack={() => updateState("defaultedCashAdvance")(null)}
        />
      );

    // Step 23
    if (!state.currentCashAdvance)
      return (
        <CurrentCashAdvanceForm
          firstName={state.firstName}
          onSubmit={updateState("currentCashAdvance")}
          onGoBack={() => updateState("filedBankruptcy")(null)}
        />
      );

    // Step 24
    if (state.currentCashAdvance === "Yes" && !state.lenderName)
      return (
        <LenderNameForm
          onSubmit={updateState("lenderName")}
          onGoBack={() => updateState("currentCashAdvance")(null)}
        />
      );

    // Step 25
    if (!state.currentBalance)
      return (
        <CurrentBalanceForm
          onSubmit={updateState("currentBalance")}
          onGoBack={() => updateState("lenderName")(null)}
        />
      );

    // Step 26
    if (!state.paymentFrequency)
      return (
        <PaymentFrequencyForm
          onSubmit={updateState("paymentFrequency")}
          onGoBack={() => updateState("currentBalance")(null)}
        />
      );

    // Step 27
    if (!state.paymentAmount)
      return (
        <PaymentAmountForm
          onSubmit={updateState("paymentAmount")}
          onGoBack={() => updateState("paymentFrequency")(null)}
        />
      );

    // Step 28
    if (state.hasCollateral === null)
      return (
        <CollateralForm
          onSubmit={updateState("hasCollateral")}
          onGoBack={() => updateState("paymentAmount")(null)}
        />
      );

    // Step 29
    if (state.hasCollateral === "Yes" && !state.position1)
      return (
        <PositionForm
          onSubmit={updateState("position1")}
          onGoBack={() => updateState("hasCollateral")(null)}
        />
      );

    // Step 30
    if (
      state.hasCollateral === "Yes" &&
      state.position1 &&
      !state.propertyAddress
    )
      return (
        <PropertyAddressForm
          firstName={state.firstName}
          onSubmit={updateState("propertyAddress")}
          onGoBack={() => updateState("position1")(null)}
        />
      );

    // Step 31
    if (!state.assetType)
      return (
        <AssetTypeForm
          onSubmit={updateState("assetType")}
          onGoBack={() => updateState("propertyAddress")(null)}
        />
      );

    // Step 32
    if (!state.yearAcquired)
      return (
        <PropertyAcquisitionYearForm
          firstName={state.firstName}
          onSubmit={updateState("yearAcquired")}
          onGoBack={() => updateState("assetType")(null)}
        />
      );

    // Step 33
    if (!state.purchasePrice)
      return (
        <PurchasePriceForm
          onSubmit={updateState("purchasePrice")}
          onGoBack={() => updateState("yearAcquired")(null)}
        />
      );

    // Step 34
    if (!state.currentValue)
      return (
        <PropertyValueForm
          onSubmit={updateState("currentValue")}
          onGoBack={() => updateState("purchasePrice")(null)}
        />
      );

    // Step 35
    if (!state.loanBalance)
      return (
        <LoanBalanceForm
          onSubmit={updateState("loanBalance")}
          onGoBack={() => updateState("currentValue")(null)}
        />
      );

    // Step 36
    if (state.otherLenders === null)
      return (
        <OtherLendersForm
          onSubmit={updateState("otherLenders")}
          onGoBack={() => updateState("loanBalance")(null)}
        />
      );

    // Step 37
    if (state.otherLenders === "Yes" && !state.lenderName1)
      return (
        <LenderNamesForm
          onSubmit={updateState("lenderName1")}
          onGoBack={() => updateState("otherLenders")(null)}
        />
      );

    // Step 38
    if (
      (state.otherLenders === "No" || state.lenderName1) &&
      !state.titleHolders
    )
      return (
        <TitleHoldersForm
          onSubmit={(values) => {
            updateState("titleHolders")(values);
            setFinishedSurvey(true);
          }}
          onGoBack={() => updateState("lenderName1")(null)}
          onFinalSubmit={handleSubmit}
        />
      );
    if (finishedSurvey) {
      return (
        <ThankYou companyName={state.officialBusinessName} onSubmit={reset} />
      );
    }
  };

  return (
    <div className="survey-popup fixed inset-0 bg-white bg-opacity-5 flex justify-center items-center z-[1010]">
      <div className="survey-content bg-white p-4 rounded-lg shadow-lg max-w-lg w-full relative">
        {renderCurrentForm()}
      </div>
    </div>
  );
};

export default SurveyFlow;
