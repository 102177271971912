import React from 'react';
import IconLis from './IconLis';
import backgroundImage from '../../assets/images/e3.jpeg'; // Ensure this path is correct

const Ourpro = () => {
  // Example data array
  const data = [
    {
      title: 'Our Process',
      para: 'Our equipment financing process is designed with your convenience in mind:',
      backgroundImage: backgroundImage, // Use the imported background image
    },
  ];

  return (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div
            className="relative bg-cover bg-fixed h-[432px] flex items-center justify-center bg-center"
            style={{
              backgroundImage: `url(${item.backgroundImage})`,
            }}
          >
            {/* Overlay with black background and opacity */}
            <div className="absolute inset-0 bg-black opacity-60"></div>

            <h1 className="relative z-10 text-white text-[32px] md:text-[42px] -mt-11 font-bold text-center px-4 md:px-0">
              {item.title}
            </h1>
          </div>

          <div className="bg-white my-7 px-3 md:px-0">
            <div className="text-center md:px-[40px] lg:px-[100px] xl:px-[276px] text-[18px] md:text-[25px]">
              {item.para}
            </div>
          </div>
        </div>
      ))}
      <IconLis />
    </>
  );
};

export default Ourpro;
