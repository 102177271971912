import React from "react";
import backgroundImage from "../../assets/images/image1.jpeg";
import Card from "./Card";
import useSurveyStore from "../../store/useSurveyStore";

const WhyChoose = () => {
  const { openSurvey } = useSurveyStore();
  return (
    <>
      <div className="relative">
        {/* Background Image */}
        <div
          className="bg-cover bg-fixed h-[432px] flex items-center justify-center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          {/* Black Overlay */}
          <div className="absolute top-0 left-0 h-[432px] right-0 bottom-0 bg-black opacity-50 z-0"></div>

          {/* Content */}
          <h1 className="text-white text-[32px] md:text-[42px] -mt-11 font-bold text-center px-4 md:px-0 z-10 relative">
            Why Choose CashAdvanceUsa?
          </h1>
        </div>

        <div className="bg-white my-11 container mx-auto">
          <div className="text-center text-[19px] md:text-[19px]">
            <p>
              Through our array of funding initiatives across the United States,
              entrepreneurs find their funding capacities tailored to their cash
              flow dynamics. Access to increased funds is facilitated even prior
              to the complete clearance of existing obligations. Whether your
              goal is a higher monetary threshold, extended repayment durations,
              or consolidation strategies, CashAdvanceUsa is eager to explore
              the optimal funding avenues suited to your needs.
            </p>
            <div className="my-2">
              <p>
                Contact us for more information, or conveniently submit an
                application online.
              </p>
            </div>
          </div>
          <div className="text-center my-4">
            <button
              className="border font-bold bg-[#37ca37] rounded-md text-xl md:text-[20px] text-white py-3 md:py-3 px-5 md:px-7"
              onClick={openSurvey}
            >
              LEARN MORE
            </button>
          </div>
        </div>
      </div>
      <Card />
    </>
  );
};

export default WhyChoose;
