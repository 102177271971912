import React from "react";

const Stream = () => {
  return (
    <>
   <div className="flex justify-center bg-blue-500 p-6 sm:p-8 md:px-[120px]">
  <div className="text-[19px] sm:text-[19px] md:text-[19px] text-white max-w-[90%] sm:max-w-[70rem] p-2 ">
    <p>
      At CashAdvanceUsa, we streamline the comprehension of your funding terms before you finalize agreements. Transparency is paramount
      to us, ensuring you possess the necessary information to navigate financing choices for your business effectively.
    </p>
    <p>
      Lenders often present pricing in varying formats, complicating comparisons. That's why Cash Advance USA simplifies understanding
      and securing funds through MCAs.
    </p>
    <p>
      Through our funding initiatives, entrepreneurs ascertain maximum limits aligned with their cash flow, accessing additional funds
      before current obligations are settled.
    </p>
  </div>
</div>

      
    </>
  );
};

export default Stream;
