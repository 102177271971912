import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const PaymentAmountForm = ({ onSubmit, onGoBack }) => {
    const [paymentAmount, setPaymentAmount] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value)) { // Ensure the value is a number
            setPaymentAmount(value);
            setError(''); // Clear error if input is valid
        } else {
            setError('Please enter a valid number');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (paymentAmount.trim() === '') {
            setError('Payment amount is required');
        } else {
            onSubmit(paymentAmount);
        }
    };

    return (
        <QuestionTemplate
            title="And What Is The Payment Amount?"
            description="Sum of money that is due for payment on a financial obligation, such as a loan installment or credit card bill."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4 relative">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="paymentAmount"
                    placeholder="Payment Amount"
                    value={paymentAmount}
                    onChange={handleChange}
                    className={`mt-1 block w-full pl-8 pr-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default PaymentAmountForm;
