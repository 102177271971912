import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Header from '../components/adminDashboard/Heder.jsx';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Analytics = () => {
    const [analyticsData, setAnalyticsData] = useState({
        sessions: 58134,
        conversions: 1538,
        conversionRate: '2.65%',
        completionRate: '1.51%',
        pageVisits: [58100, 1538, 265, 151],
    });

    const chartData = {
        labels: ['Page 1', 'Page 2', 'Page 3', 'Page 4'],
        datasets: [
            {
                label: 'Page Visits',
                data: analyticsData.pageVisits,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Page Visits',
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <Header activeTab="Analytics" />
            <StatsGrid data={analyticsData} />
            <ChartCard data={chartData} options={chartOptions} title="Page Visits" />
        </div>
    );
};

const StatsGrid = ({ data }) => {
    const stats = [
        { label: 'Sessions', value: data.sessions },
        { label: 'Conversions', value: data.conversions },
        { label: 'Conversion Rate', value: data.conversionRate },
        { label: 'Completion Rate', value: data.completionRate },
    ];

    return (
        <div className="grid grid-cols-4 gap-6 mb-6">
            {stats.map((stat, index) => (
                <div key={index} className="p-4 bg-white shadow-lg rounded-lg text-center">
                    <h3 className="text-lg font-semibold">{stat.label}</h3>
                    <p className="text-3xl font-bold">{stat.value}</p>
                </div>
            ))}
        </div>
    );
};

const ChartCard = ({ data, options, title }) => {
    return (
        <div className="p-6 bg-white shadow-lg rounded-lg">
            <h3 className="text-lg font-semibold mb-4">{title}</h3>
            <Bar data={data} options={options} />
        </div>
    );
};

export default Analytics;
