import React from "react";
import SurveyFlow from "../components/SurveyFlow";
import useSurveyStore from "../store/useSurveyStore";
import List from "../components/pages/List";
import WhyChoose from "../components/pages/WhyChoose";
import MerchantCash from "../components/pages/MerchantCash";
import Stream from "../components/pages/Stream";
import Bestcombination from "../components/pages/Bestcombination";
import Calltoday from "../components/pages/Calltoday";
import GetStarted from "../components/pages/GetStarted";
import Form from "../components/pages/Form";
import heroVideo from "../assets/videos/hero.mp4"; // Adjust the path if necessary

const HomePage = () => {
  const { openSurvey } = useSurveyStore();

  return (
    <div>
      <div className="relative h-screen overflow-hidden">
        <video className="w-full h-full object-cover" autoPlay loop muted>
          <source src={heroVideo} type="video/mp4" />
        </video>

        <div className="absolute top-0 left-0 right-0 bottom-0 h-full bg-black opacity-70 z-0"></div>

        <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex flex-col justify-center items-center h-full text-center text-white">
          <h1 className="text-3xl md:text-[33px] -mt-[25px]">
            <b>Empower</b> your Small &
          </h1>
          <h1 className="text-2xl md:text-[33px] mt-[20px]">
            Medium sized <b>business</b>
            <p className="text-[23px] md:text-[23px] font-bold mt-[20px]">
              With Flexible Funding
            </p>
          </h1>
          <button
            onClick={openSurvey}
            className="bg-[#37ca37] mt-5 text-white font-bold rounded-lg w-[180px] h-[40px] md:w-[191px] md:h-[50px] text-[16px] md:text-[30px]"
          >
            <span className="block text-[12px] md:text-[22px]">
              Get Started
            </span>
          </button>
        </div>

        {/* Contact and Apply Now */}
        <div className="absolute top-5 left-1/2 transform -translate-x-1/2 md:top-5 md:left-auto md:right-3 md:transform-none flex gap-4 sm:gap-9 z-50 w-full md:w-auto md:justify-end">
          <a
            href="tel:+18447682769"
            className="bg-blue-500 text-white font-bold py-3 m-2 px-6 rounded-md text-center md:text-[15px]"
          >
            +1 (844) 768-2769
          </a>
          <span
            onClick={openSurvey}
            className="bg-green-500 text-white font-bold cursor-pointer py-3 m-2 px-6 rounded-md text-center md:text-[15px]"
          >
            Apply Now
          </span>
        </div>
      </div>

      {/* Other Components */}
      <List />
      <WhyChoose />
      <MerchantCash />
      <Stream />
      <Bestcombination />
      <Calltoday />
      <GetStarted />
      <Form />
    </div>
  );
};

export default HomePage;
