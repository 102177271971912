import React from 'react';
import QuestionTemplate from './QuestionTemplate';

const CollateralForm = ({ onSubmit, onGoBack }) => {
    return (
        <QuestionTemplate
            title="Only One More Topic We Need To Discuss! Do You Have Any Collateral?"
            description="We ask about collateral to ensure responsible lending and offer you the best terms. Rest assured, you don't need to have it; we have offers available with no collateral."
            onGoBack={onGoBack}
        >
            <div className="flex flex-col gap-4">
                <button
                    className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
                    onClick={() => onSubmit('Yes')}
                >
                    Yes
                </button>
                <button
                    className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out  focus:outline-none"
                    onClick={() => onSubmit('No')}
                >
                    No
                </button>
            </div>
        </QuestionTemplate>
    );
};

export default CollateralForm;
