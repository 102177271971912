import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const PurchasePriceForm = ({ onSubmit, onGoBack }) => {
    const [purchasePrice, setPurchasePrice] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        setPurchasePrice(value);
        if (value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (purchasePrice.trim() === '') {
            setError('Purchase price is required');
        } else {
            onSubmit(purchasePrice);
        }
    };

    return (
        <QuestionTemplate
            title="What Was The Purchase Price?"
            description="We request the purchase price to understand the initial investment in the property, providing context for its current value assessment and aiding in the evaluation of collateral for your loan application."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4 relative">
                <span className="absolute left-3 top-2.5 text-gray-500">$</span>
                <input
                    type="text"
                    name="purchasePrice"
                    placeholder="Purchase Price"
                    value={purchasePrice}
                    onChange={handleChange}
                    className={`mt-1 block w-full pl-8 px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default PurchasePriceForm;
