import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const OwnershipPercentageForm = ({ firstName, onSubmit, onGoBack }) => {
    const [ownershipPercentage, setOwnershipPercentage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setOwnershipPercentage(e.target.value);
        if (e.target.value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (ownershipPercentage.trim() === '') {
            setError('Ownership percentage cannot be blank');
        } else if (isNaN(ownershipPercentage) || ownershipPercentage < 1 || ownershipPercentage > 100) {
            setError('Please enter a valid percentage between 1 and 100');
        } else {
            onSubmit(ownershipPercentage);
        }
    };

    return (
        <QuestionTemplate
            title={`Amazing! What Is The Exact Ownership Percentage ${firstName}?`}
            description="We request your ownership percentage to understand your stake in the business, helping us determine loan eligibility and terms."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <label htmlFor="ownershipPercentage" className="block text-left mb-2">
                    Ownership Percentage
                </label>
                <input
                    id="ownershipPercentage"
                    type="number"
                    name="ownershipPercentage"
                    placeholder="1-100%"
                    value={ownershipPercentage}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default OwnershipPercentageForm;
