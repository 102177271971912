import React from 'react';
import backgroundImage from "../../assets/images/s4.jpeg";

const Flexible = () => {
  return (
    <>
      <div
        className="relative bg-cover bg-fixed h-[432px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
       
        <div className="absolute inset-0 bg-black opacity-60"></div>

        <h1 className="relative z-10 text-white text-[32px] md:text-[42px] -mt-11 font-bold text-center px-4 md:px-0">
          Flexible Repayment Options
        </h1>
      </div>

      <div className="bg-white mt-[1rem] py-9 px-3 md:px-0">
        <div className="text-center md:px-[40px] lg:px-[100px] xl:px-[40px] text-[18px] md:text-[17px]">
        <p className='tracking-tight'>Each business experiences its distinct cash flow patterns. Hence, we provide adaptable repayment plans customized to align with your business's individual requirements. Our objective is to furnish you with working capital loans for your small or medium-sized enterprises, fostering growth without imposing excessive financial pressure.
        </p>  </div>
      </div>
    </>
  );
}

export default Flexible;
