import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUsers, fetchSessions, fetchConversions, fetchLeads } from '../api';
import Header from '../components/adminDashboard/Heder';

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [conversions, setConversions] = useState([]);
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMoreUsers, setShowMoreUsers] = useState(false);
    const [showMoreSessions, setShowMoreSessions] = useState(false);
    const [showMoreConversions, setShowMoreConversions] = useState(false);
    const [showMoreLeads, setShowMoreLeads] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('adminToken');
                if (!token) {
                    navigate('/admin/login');
                    return;
                }

                const [usersData, sessionsData, conversionsData, leadsData] = await Promise.all([
                    fetchUsers(),
                    fetchSessions(),
                    fetchConversions(),
                    fetchLeads(),
                ]);

                setUsers(usersData);
                setSessions(sessionsData);
                setConversions(conversionsData);
                setLeads(leadsData);
            } catch (error) {
                setError('Failed to fetch data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    const displayItems = 3; // Number of items to display initially

    if (loading) {
        return <div className="text-center text-gray-600">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-500">{error}</div>;
    }

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <Header activeTab="Dashboard" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Users Section */}
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Users</h3>
                    <ul className="divide-y divide-gray-200">
                        {users.slice(0, showMoreUsers ? users.length : displayItems).map((user) => (
                            <li key={user.id} className="py-2">
                                <p className="font-semibold">{user.username}</p>
                                <p className="text-sm text-gray-500">{user.email}</p>
                                <p className="text-sm text-gray-400">Role: {user.role}</p>
                            </li>
                        ))}
                    </ul>
                    {users.length > displayItems && (
                        <button
                            onClick={() => setShowMoreUsers(!showMoreUsers)}
                            className="text-blue-500 hover:underline mt-4"
                        >
                            {showMoreUsers ? 'Show Less' : 'Show More'}
                        </button>
                    )}
                </div>

                {/* Sessions Section */}
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Sessions</h3>
                    <ul className="divide-y divide-gray-200">
                        {sessions.slice(0, showMoreSessions ? sessions.length : displayItems).map((session) => (
                            <li key={session.id} className="py-2">
                                <p className="font-semibold">Seen at: {session.seenAt}</p>
                                <p className="text-sm text-gray-500">Email: {session.email}</p>
                                <p className="text-sm text-gray-400">First seen at: {session.firstSeenAt}</p>
                            </li>
                        ))}
                    </ul>
                    {sessions.length > displayItems && (
                        <button
                            onClick={() => setShowMoreSessions(!showMoreSessions)}
                            className="text-blue-500 hover:underline mt-4"
                        >
                            {showMoreSessions ? 'Show Less' : 'Show More'}
                        </button>
                    )}
                </div>

                {/* Conversions Section */}
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Conversions</h3>
                    <ul className="divide-y divide-gray-200">
                        {conversions.slice(0, showMoreConversions ? conversions.length : displayItems).map((conversion) => (
                            <li key={conversion.id} className="py-2">
                                <p className="font-semibold">Seen at: {conversion.seenAt}</p>
                                <p className="text-sm text-gray-500">Email: {conversion.email}</p>
                                <p className="text-sm text-gray-400">First seen at: {conversion.firstSeenAt}</p>
                            </li>
                        ))}
                    </ul>
                    {conversions.length > displayItems && (
                        <button
                            onClick={() => setShowMoreConversions(!showMoreConversions)}
                            className="text-blue-500 hover:underline mt-4"
                        >
                            {showMoreConversions ? 'Show Less' : 'Show More'}
                        </button>
                    )}
                </div>

                {/* Leads Section */}
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Leads</h3>
                    <ul className="divide-y divide-gray-200">
                        {leads.slice(0, showMoreLeads ? leads.length : displayItems).map((lead) => (
                            <li key={lead.id} className="py-2">
                                <p className="font-semibold">{lead.officialBusinessName}</p>
                                <p className="text-sm text-gray-500">Funding: {lead.desiredFunding}</p>
                                <p className="text-sm text-gray-400">Business Activity: {lead.businessActivity}</p>
                            </li>
                        ))}
                    </ul>
                    {leads.length > displayItems && (
                        <button
                            onClick={() => setShowMoreLeads(!showMoreLeads)}
                            className="text-blue-500 hover:underline mt-4"
                        >
                            {showMoreLeads ? 'Show Less' : 'Show More'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
