import React from "react";
import QuestionTemplate from "./QuestionTemplate";

const DefaultCashAdvanceForm = ({ onSubmit, onGoBack }) => {
  return (
    <QuestionTemplate
      title="Did You Ever Default On A Cash Advance?"
      description="A default indicates a failure to fulfill financial obligations and may impact the individual's creditworthiness and eligibility for future loans."
      onSubmit={(e) => e.preventDefault()} // Prevent form submission since we're handling clicks directly
      onGoBack={onGoBack}
      hasOptionsButtons
    >
      <div className="flex flex-col gap-4">
        <button
          type="button"
          className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
          onClick={() => onSubmit("Yes")}
        >
          Yes
        </button>
        <button
          type="button"
          className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
          onClick={() => onSubmit("No")}
        >
          No
        </button>
      </div>
    </QuestionTemplate>
  );
};

export default DefaultCashAdvanceForm;
