import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import QuestionTemplate from './QuestionTemplate';

const DateOfBirthForm = ({ firstName, onSubmit, onGoBack }) => {
    const [dateOfBirth, setDateOfBirth] = useState(dayjs('2000-01-01'));
    const [error, setError] = useState('');

    const handleDateChange = (newDate) => {
        setDateOfBirth(newDate);
        if (newDate) {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!dateOfBirth) {
            setError('This is a required field');
        } else {
            onSubmit(dateOfBirth.format('YYYY-MM-DD')); // Formats the date as 'YYYY-MM-DD'
        }
    };

    return (
        <QuestionTemplate
            title={`${firstName} What's Your Date Of Birth?`}
            description="We collect your birthday to ensure compliance with age-related legal requirements and regulations."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        views={['year', 'month', 'day']} // Enable 'year', 'month', and 'day' views
                        value={dateOfBirth}
                        onChange={handleDateChange}
                        openTo="day" // Start by opening the 'day' view
                    />
                </LocalizationProvider>
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default DateOfBirthForm;
