import React from "react";
import backgroundImage from "../../assets/images/s2.jpeg";

const ChoseWorking = () => {
  return (
    <>
      <div
        className="relative bg-cover bg-fixed h-[380px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
       
        <div className="absolute inset-0 bg-black opacity-60"></div>

        <h1 className="relative z-10 text-white text-[20px] md:text-[36px] -mt-11 font-bold text-center px-4 md:px-0">
          Why Choose Working Financial Streamlining?
        </h1>
      </div>

      <div className="bg-white my-7 px-3 md:px-0">
        <div className="text-center md:px-[40px] my-11 lg:px-[100px] xl:px-[136px] text-[18px] md:text-[26px]">
          Working Financial Streamlining offers a flexible and effective solution to help you:
        </div>
      </div>
    </>
  );
};

export default ChoseWorking;
