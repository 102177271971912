import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const PropertyValueForm = ({ onSubmit, onGoBack }) => {
    const [currentValue, setCurrentValue] = useState('');
    const [error, setError] = useState('');
    const [dontKnowValue, setDontKnowValue] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        setCurrentValue(value);
        if (value.trim() !== '') {
            setError('');
        }
    };

    const handleCheckboxChange = () => {
        setDontKnowValue(!dontKnowValue);
        if (!dontKnowValue) {
            setCurrentValue('');
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!dontKnowValue && currentValue.trim() === '') {
            setError('Current value is required');
        } else {
            onSubmit(dontKnowValue ? 'Unknown' : currentValue);
        }
    };

    return (
        <QuestionTemplate
            title="And What Is The Current Value Of The Property?"
            description="We ask for the current value of the property to accurately assess its worth as collateral, ensuring fair evaluation and appropriate loan terms for your application."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4 relative">
                <span className="absolute left-3 top-2.5 text-gray-500">$</span>
                <input
                    type="text"
                    name="currentValue"
                    placeholder="Current Value"
                    value={currentValue}
                    onChange={handleChange}
                    disabled={dontKnowValue}
                    className={`mt-1 block w-full pl-8 px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
            <div className="mb-4 text-left">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={dontKnowValue}
                        onChange={handleCheckboxChange}
                        className="form-checkbox"
                    />
                    <span className="ml-2">I Don't Know The Value</span>
                </label>
            </div>
        </QuestionTemplate>
    );
};

export default PropertyValueForm;
