import React from 'react';
import QuestionTemplate from './QuestionTemplate';

const AssetTypeForm = ({ onSubmit, onGoBack }) => {
    return (
        <QuestionTemplate
            title="What Is The Asset Type?"
            description="Inquiring about asset types in a cash advance business helps gauge risk, collateral options, and repayment ability, while ensuring regulatory compliance."
            onSubmit={() => onSubmit('')}
            onGoBack={onGoBack}
            hasOptionsButtons={true}
        >
            <div className="flex flex-col gap-4">
                <button
                    type="button"
                    className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
                    onClick={() => onSubmit('Residential')}
                >
                    Residential
                </button>
                <button
                    type="button"
                    className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
                    onClick={() => onSubmit('Commercial')}
                >
                    Commercial
                </button>
            </div>
        </QuestionTemplate>
    );
};

export default AssetTypeForm;
