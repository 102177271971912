import React from "react";
import EquipmentFinancing from "./EquipmentFinancing";
import defaultBackgroundImage from "../assets/images/pic3.jpg";
import ChooseEquipment from "../components/pages/ChooseEquipment";
import backgroundImage2 from "../assets/images/pic4.jpg";
import FinancialListTwo from "../components/pages/FinancialListTwo";
import { GiAlarmClock } from "react-icons/gi";
import { LuClipboardSignature } from "react-icons/lu";
import { VscGraphLine } from "react-icons/vsc";
import { LuClipboardList } from "react-icons/lu";
import Ourpro from "../components/pages/Ourpro";
import GetStarted from "../components/pages/GetStarted";
import useSurveyStore from "../store/useSurveyStore";
const MerchantCashAdvance = ({
  backgroundImage = defaultBackgroundImage,
  title = "Merchant Cash Advance",
  subtitle = "Gain instant access to funds through a Merchant Cash Advance.",
  description = "In a fiercely competitive business environment, staying ahead requires access to cutting-edge equipment and technology. Our equipment financing options empower you to procure the necessary assets for your business without immobilizing your valuable capital. Invest in your business's future while safeguarding your cash flow.",
  btnText = "GET STARTED",
}) => {
  const data = [
    {
      id: 1,
      name: "Fast funding for immediate needs",
      para: "Quickly access funds to handle unforeseen expenses or seize time-sensitive opportunities",
      icon: <GiAlarmClock />,
    },
    {
      id: 2,
      name: "Repayment based on daily credit card sales percentage.",
      para: "Your repayment is linked to a portion of your daily credit card sales, offering flexibility during periods of revenue fluctuation.",
      icon: <LuClipboardSignature />,
    },
    {
      id: 3,
      name: "No fixed repayment schedule",
      para: "Unlike traditional loans, MCAs don't have a fixed repayment schedule, allowing for more manageable repayments during slower sales periods.",
      icon: <VscGraphLine />,
    },
    {
      id: 4,
      name: "Minimal Paperwork",
      para: "Our streamlined application process requires minimal documentation, ensuring a fast and efficient funding process.",
      icon: <LuClipboardList />,
    },
  ];
  const data1 = [
    {
      title: "Our Process",
      para: "We make the MCA process simple and hassle-free::",
      backgroundImage: "../../assets/images/pic6.jpg",
    },
  ];
  const { openSurvey } = useSurveyStore();

  return (
    <>
      <div
        className="bg-cover bg-fixed h-[510px] flex items-center justify-center relative"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="z-10">
          <h1 className="text-white text-[32px] md:text-[42px] -mt-11 font-bold text-center px-4 md:px-0">
            {title}
            <br />
            <p className="text-3xl"> {subtitle}</p>
          </h1>
        </div>
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>
      <div className="bg-white my-7 px-3  md:px-0">
        <div className="text-center md:px-[40px] lg:px-[100px] xl:px-[276px] text-[18px] md:text-[15px] font-semibold">
          {description}{" "}
        </div>
        <div className="text-center my-4">
          <button
            className="border bg-green-500 rounded-lg font-semibold text-[7px] md:text-[23px] text-white py-3 md:py-3 px-5 md:px-6"
            onClick={openSurvey}
          >
            {btnText}
          </button>
        </div>
      </div>
      <ChooseEquipment
        backgroundImage={backgroundImage2}
        title="Why Choose Merchant Cash Advance?"
        description="An MCA presents numerous benefits for businesses with robust credit card sales."
      />
      <FinancialListTwo data={data} />
      <Ourpro />
      <GetStarted />
    </>
  );
};

export default MerchantCashAdvance;
