import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const SsnForm = ({ firstName, onSubmit, onGoBack }) => {
    const [ssn, setSsn] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        // Allow only numbers
        if (/^\d*$/.test(value)) {
            setSsn(value);
            setError('');
        } else {
            setError('SSN must contain only numbers');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (ssn.trim() === '') {
            setError('This is a required field');
        } else if (ssn.length !== 9) {
            setError('SSN must be 9 digits long');
        } else {
            onSubmit(ssn);
        }
    };

    return (
        <QuestionTemplate
            title={`Almost Finished! ${firstName}, Please Provide Your Social Security Number.`}
            description="We perform a routine soft credit check. This will not impact your credit score, it helps us verify your identity and process your application efficiently. These digits will be encrypted and kept secure."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <input
                    type="text"
                    name="ssn"
                    placeholder="Social Security Number"
                    value={ssn}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    maxLength="9" // Set maximum length to 9 digits
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default SsnForm;

