import React from "react";
import backgroundImage from "../assets/images/662ed3ec2d01fa7414aaee33.jpeg";
import Form from "../components/pages/Form";
import GetStarted from "../components/pages/GetStarted";
import useSurveyStore from "../store/useSurveyStore";

const ContactUsPage = () => {
  const { openSurvey } = useSurveyStore();
  return (
    <>
      <div className="relative">
        <div
          className="bg-cover bg-fixed bg-center h-[500px] flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>

          <h1 className="text-white text-4xl font-bold relative z-10">
            Contact Us
          </h1>
        </div>
        <div className="w-[80%] mx-auto">
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="mt-[20%]">
              <h1 className="text-[30px] font-bold">Send Us a Message</h1>
              <p className="text-xl">
                Please complete the form with your questions and inquiries. We
                will get back to you as soon as possible.
              </p>
              <div className="rounded-lg overflow-hidden">
                <iframe
                  title="Cash Advance"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11672.945750644447!2d-122.42107853750231!3d37.7730507907087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858070cc2fbd55%3A0xa71491d736f62d5c!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1619524992238!5m2!1sen!2sus"
                  width="100%"
                  height="480"
                  className="mt-9"
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <div className="absolute top-5 right-9 flex gap-4 z-10">
                <button className="bg-blue-500 cursor-pointer text-white font-bold py-4 px-7 rounded-md">
                  +1 (844) 768-2769
                </button>
                <button
                  className="bg-green-500 cursor-pointer text-white font-bold py-4 px-7 rounded-md"
                  onClick={openSurvey}
                >
                  Apply Now
                </button>
              </div>
            </div>
            <div>
              <Form />
            </div>
          </div>
        </div>

        <GetStarted />
      </div>
    </>
  );
};

export default ContactUsPage;
