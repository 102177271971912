// src/api.js
import axios from 'axios';
import { mockUsers, mockSessions, mockConversions, mockLeads } from './mockData';

const isProduction = process.env.REACT_APP_ENV === 'production';

export const fetchUsers = async () => {
    if (isProduction) {
        // Actual API call in production
        const response = await axios.get('/api/admin/users');
        return response.data;
    } else {
        // Mock data in development
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockUsers);
            }, 500); // Simulate a delay
        });
    }
};

export const fetchSessions = async () => {
    if (isProduction) {
        const response = await axios.get('/api/admin/sessions');
        return response.data;
    } else {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockSessions);
            }, 500);
        });
    }
};

export const fetchConversions = async () => {
    if (isProduction) {
        const response = await axios.get('/api/admin/conversions');
        return response.data;
    } else {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockConversions);
            }, 500);
        });
    }
};

export const fetchLeads = async () => {
    if (isProduction) {
        const response = await axios.get('/api/admin/leads');
        return response.data;
    } else {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockLeads);
            }, 500);
        });
    }
};
