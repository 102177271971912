import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const PropertyAddressForm = ({ onSubmit, onGoBack, firstName }) => {
    const [formData, setFormData] = useState({
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <QuestionTemplate
            title={`${firstName}, Would You Mind Sharing With Us The Property Address?`}
            description="We request the property address to verify ownership and assess the value of the collateral, ensuring accurate and efficient processing of your loan application."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <input
                    type="text"
                    name="street"
                    placeholder="The street"
                    value={formData.street}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="city"
                    placeholder="The city"
                    value={formData.city}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="state"
                    placeholder="Business State/Region/Province"
                    value={formData.state}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="zipCode"
                    placeholder="The zip code"
                    value={formData.zipCode}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    name="country"
                    placeholder="The country"
                    value={formData.country}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
        </QuestionTemplate>
    );
};

export default PropertyAddressForm;
