import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const LastNameForm = ({ firstName, onSubmit, onGoBack }) => {
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setLastName(e.target.value);
        if (e.target.value.trim() !== '') {
            setError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (lastName.trim() === '') {
            setError('This is a required field');
        } else {
            onSubmit(lastName);
        }
    };

    return (
        <QuestionTemplate
            title={`Nice To Meet You ${firstName}, What's Your Last Name?`}
            description="I only have a few more questions to ask you in order to finish the first step and open a cash advance request."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <label className="block text-left">
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Your last name"
                        value={lastName}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {error && <span className="text-red-500 text-sm">{error}</span>}
                </label>
            </div>
        </QuestionTemplate>
    );
};

export default LastNameForm;
