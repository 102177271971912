import React, { useState } from "react";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import QuestionTemplate from "./QuestionTemplate";

const CompanyInfoForm = ({ businessName, onSubmit, onGoBack }) => {
  const [formData, setFormData] = useState({
    street: "",
    unit: "",
    city: "",
    state: "",
    zipCode: "",
    taxId: "",
    phoneNumber: "",
    businessStartDate: dayjs("2000-01-01"),
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (newDate) => {
    setFormData((prevState) => ({ ...prevState, businessStartDate: newDate }));
    if (newDate) {
      setErrors((prevErrors) => ({ ...prevErrors, businessStartDate: "" }));
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex =
      /^(\+?1\s?)?(\([0-9]{3}\)|[0-9]{3})[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
    return phoneRegex.test(number);
  };

  const validateForm = () => {
    const formErrors = {};

    if (!formData.street) formErrors.street = "Street is required";
    if (!formData.city) formErrors.city = "City is required";
    if (!formData.state) formErrors.state = "State is required";
    if (!formData.zipCode) formErrors.zipCode = "Zip code is required";
    if (!formData.taxId) formErrors.taxId = "Tax ID is required";

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone number is required";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      formErrors.phoneNumber = "Please enter a valid phone number";
    }

    if (!formData.businessStartDate)
      formErrors.businessStartDate = "Business start date is required";

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const formattedData = {
        ...formData,
        businessStartDate: formData.businessStartDate.format("YYYY-MM"),
      };
      onSubmit(formattedData);
    } else {
      setErrors(formErrors);
    }
  };

  const renderInput = (name, placeholder, type = "text") => (
    <div className="mb-4">
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={formData[name]}
        onChange={handleChange}
        className={`mt-1 block w-full px-3 py-2 border ${
          errors[name] ? "border-red-500" : "border-gray-300"
        } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
      />
      {errors[name] && (
        <span className="text-red-500 text-sm">{errors[name]}</span>
      )}
    </div>
  );

  return (
    <QuestionTemplate
      title={`Excellent! Now We Need ${businessName}'s Company Info`}
      description="We kindly request that you provide us with some essential details about your company. Specifically, we need the physical address of your company, its tax ID, and the business start date. This information is crucial for processing and verification purposes."
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
    >
      {renderInput("street", "Street")}
      {renderInput("unit", "Unit (optional)")}
      {renderInput("city", "City")}
      {renderInput("state", "State/Region/Province")}
      {renderInput("zipCode", "Zip Code")}
      {renderInput("taxId", "Tax ID")}
      {renderInput("phoneNumber", "Your Business Phone Number")}

      <div className="mb-4">
        <label className="block text-left mb-2">Business Start Date:</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            views={["year", "month"]}
            value={formData.businessStartDate}
            onChange={handleDateChange}
            openTo="month"
          />
        </LocalizationProvider>
        {errors.businessStartDate && (
          <span className="text-red-500 text-sm">
            {errors.businessStartDate}
          </span>
        )}
      </div>
    </QuestionTemplate>
  );
};

export default CompanyInfoForm;
