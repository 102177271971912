import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const CurrentBalanceForm = ({ onSubmit, onGoBack }) => {
    const [currentBalance, setCurrentBalance] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value)) { // Ensure the value is a number
            setCurrentBalance(value);
            setError(''); // Clear error if input is valid
        } else {
            setError('Please enter a valid number');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (currentBalance.trim() === '') {
            setError('Current balance is required');
        } else {
            onSubmit(currentBalance);
        }
    };

    return (
        <QuestionTemplate
            title="May I Ask What's The Current Balance?"
            description="We ask for your current balance to assess your financial standing accurately and to provide you with the most suitable cash advance options tailored to your needs and circumstances."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4 relative">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="currentBalance"
                    placeholder="Current balance"
                    value={currentBalance}
                    onChange={handleChange}
                    className={`mt-1 block w-full pl-8 pr-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default CurrentBalanceForm;
