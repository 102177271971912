import React from 'react';
import Header from '../components/adminDashboard/Heder.jsx';
import { mockConversions } from '../mockData';

const Conversions = () => {
    return (
        <div className="p-6 max-w-7xl mx-auto">
            <Header activeTab="Conversions" />
            <h1 className="text-3xl font-bold mb-4">1561 Conversions</h1>
            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Seen at
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email address
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                First seen at
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {mockConversions.map((conversion) => (
                            <tr key={conversion.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {conversion.seenAt}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {conversion.email}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {conversion.firstSeenAt}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="p-4 text-center">
                    <button className="text-blue-500 hover:underline">Show more</button>
                </div>
            </div>
        </div>
    );
};

export default Conversions;
