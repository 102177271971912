import React from "react";
import QuestionTemplate from "./QuestionTemplate";
const options = [
  { label: "Less than 6 months", value: "Less than 6 months" },
  { label: "6 - 12 months", value: "6 - 12 months" },
  { label: "1 - 2 years", value: "1 - 2 years" },
  { label: "2 - 5 years", value: "2 - 5 years" },
  { label: "5 + years", value: "5 + years" },
];
const BusinessActiveForm = ({ businessName, onSubmit, onGoBack }) => {
  const handleActiveChange = (isActive) => {
    onSubmit(isActive);
  };

  return (
    <QuestionTemplate
      title={`How long has ${businessName} been in business?`}
      description="We inquire about the duration that dsa has been in operation to assess risk factors and determine the most suitable type of financing for you."
      onGoBack={onGoBack}
      hasOptionsButtons={true}
    >
      <div className="flex flex-col gap-4 mb-4">
        {options.map((option, index) => (
          <button
            onClick={() => handleActiveChange(option.value)}
            className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none "
          >
            {option.label}
          </button>
        ))}
      </div>
    </QuestionTemplate>
  );
};

export default BusinessActiveForm;
