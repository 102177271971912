import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

const useFormState = () => {
  // Define the initial state for all form fields
  const [state, setState] = useState({
    desiredFunding: null,
    officialBusinessName: null,
    typeOfBankAccount: null,
    businessActivity: null,
    monthlyRevenue: null,
    industry: null,
    firstName: null,
    lastName: null,
    phoneNumberAndEmail: null,
    phoneNumber: null,
    email: null,
    dateOfBirth: null,
    ficoScore: null,
    socialSecurityNumber: null,
    homeAddress: null,
    utilizationOfFunds: null,
    legalStructure: null,
    owns51Percent: null,
    ownershipPercentage: null,
    businessInfo: null,
    liensOrJudgments: null,
    defaultedCashAdvance: null,
    filedBankruptcy: null,
    currentCashAdvance: null,
    lenderName: null,
    currentBalance: null,
    paymentFrequency: null,
    paymentAmount: null,
    hasCollateral: null,
    position1: null,
    propertyAddress: null,
    assetType: null,
    yearAcquired: null,
    purchasePrice: null,
    currentValue: null,
    loanBalance: null,
    otherLenders: null,
    lenderName1: null,
    titleHolders: null,
    bankStatements: null,
    utmContent: null,
    utmMedium: null,
  });

  // const [isQualifiedLead, setIsQualifiedLead] = useState(null);

  // const QualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/03e84bf7-234d-42a4-91d3-2a64e9b1de0f';
  // const UnQualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/9d07c39b-b44e-4395-a99d-8b7402f5516d';

  // Function to determine if the lead is qualified
  // const determineQualification = useCallback(() => {
  //     const isQualified =
  //         state.typeOfBankAccount === 'business bank account' &&
  //         state.businessActivity === 'More than a year' &&
  //         state.monthlyRevenue >= 15000;
  //     setIsQualifiedLead(isQualified);
  // }, [state.typeOfBankAccount, state.businessActivity, state.monthlyRevenue]);

  // useEffect(() => {
  //     determineQualification();
  // }, [determineQualification]);

  // Function to handle the form submission
  const handleSubmit = useCallback(
    async (updatedState) => {
      // const url = isQualifiedLead ? QualifiedLeadURL : UnQualifiedLeadURL;
      try {
        state.email = state.phoneNumberAndEmail.email
        state.phoneNumber = state.phoneNumberAndEmail.phoneNumber
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/answer/cash-advance/go-high-level`,
          {
            method: "POST",
            body: JSON.stringify(state),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("An error occurred while submitting your data.");
      }
    },
    [state]
  );

  // Function to update the form state
  const updateState = (field) => (value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (
      state.phoneNumberAndEmail ||
      state.currentCashAdvance ||
      state.titleHolders
    ) {
      handleSubmit(state);
    }
  }, [state, handleSubmit]);

  return [state, updateState];
};

export default useFormState;
