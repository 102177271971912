import React, { useCallback, useEffect } from "react";
import jsPDF from "jspdf"; // Import jsPDF for PDF generation

const Plaid = () => {
  const createLinkToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/plaid/create_link_token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to create link token: ${response.statusText}`);
      }

      const { link_token } = await response.json();
      return link_token;
    } catch (error) {
      console.error("Error creating link token:", error);
      return null; // Return null if there's an error
    }
  };

  const downloadPDF = (transactions) => {
    // Ask the user for confirmation before downloading the PDF
    const confirmDownload = window.confirm("Do you want to download the bank statement as a PDF?");

    if (!confirmDownload) {
      // If the user clicks "Cancel", exit the function
      return;
    }

    const doc = new jsPDF();

    // Add a title
    doc.setFontSize(16);
    doc.text("Bank Statement", 10, 10);

    // Table headers
    doc.setFontSize(12);
    doc.text("Date", 10, 20);
    doc.text("Amount", 50, 20);
    doc.text("Category", 90, 20);
    doc.text("Merchant", 150, 20);

    // Table content
    transactions.forEach((txn, index) => {
      const y = 30 + index * 10; // Adjust Y position for each row
      doc.text(txn.date, 10, y);
      doc.text(txn.amount.toString(), 50, y);
      doc.text(txn.category ? txn.category.join(", ") : "N/A", 90, y);
      doc.text(txn.merchant_name || "N/A", 150, y);
    });

    // Save the PDF
    doc.save("bank_statement.pdf");
  };

  const initializePlaid = useCallback(async () => {
    try {
      const linkToken = await createLinkToken();

      if (!linkToken) {
        console.error("Link token is undefined");
        return;
      }

      const handler = window.Plaid.create({
        token: linkToken,
        onSuccess: async (public_token, metadata) => {
          try {
            // Exchange public token for access token
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/plaid/exchange_public_token`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ public_token }),
              }
            );

            if (!response.ok) {
              throw new Error(
                `Failed to exchange public token: ${response.statusText}`
              );
            }

            const { access_token } = await response.json();

            // Retrieve transactions
            const transactionsResponse = await fetch(
              `${process.env.REACT_APP_BASE_URL}/plaid/get_transactions`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ access_token }),
              }
            );

            if (!transactionsResponse.ok) {
              throw new Error(
                `Failed to retrieve transactions: ${transactionsResponse.statusText}`
              );
            }

            const { transactions } = await transactionsResponse.json();
            console.log("Transactions:", transactions);

            // Ask for confirmation and then download the PDF
            downloadPDF(transactions);
          } catch (error) {
            console.error("Error during onSuccess flow:", error);
          }
        },
        onExit: (err, metadata) => {
          if (err) {
            console.error("User exited Plaid Link:", err);
          }
        },
      });

      document.getElementById("link-button").onclick = () => {
        handler.open();
      };
    } catch (error) {
      console.error("Error initializing Plaid:", error);
    }
  }, []);

  useEffect(() => {
    // Load Plaid Link script
    const script = document.createElement("script");
    script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      initializePlaid();
    };

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [initializePlaid]);

  return (
    <div className="flex items-center justify-center h-screen">
      <button
        className="p-4 font-bold bg-gradient-to-r from-black via-green-700 to-green-500 text-white rounded-md shadow-md hover:bg-gradient-to-r hover:from-black hover:via-green-800 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
        id="link-button"
      >
        Link Bank Account
      </button>
    </div>
  );
};

export default Plaid;
