import React from 'react'
// import { IoBriefcaseOutline } from "react-icons/io5";
// import { GrTrophy } from "react-icons/gr"
// import { BsCashCoin } from "react-icons/bs";
// import { CiBank } from "react-icons/ci";
import business from '../../assets/images/icon/busi.png'
import Cup from '../../assets/images/icon/cup.png'
import Price from '../../assets/images/icon/price.png'
import bank from '../../assets/images/icon/bank.png'
const data=[
    {id:'1',name:'Time in Business',desc:"6 Months",icon:<img src={business} className='w-[60-px] h-[60px]'/>},
    {id:'2',name:'Personal FICO Score',desc:"500+",icon:<img src={Cup} className='w-[60-px] h-[60px]'/>},
    {id:'3',name:'Business Annual Revenue',desc:"$125k+",icon:<img src={Price} className='w-[60-px] h-[60px]'/>},
    {id:'4',name:'Business Bank Account',desc:"yes",icon:<img src={bank} className='w-[60-px] h-[60px]'/>},
]
const CardList = () => {
  return (
    <>
    <div className='flex flex-wrap gap-2 justify-center my-9 sm:my-10 text-center'>
  {data.map((item) => (
    <div
      key={item.id}
      className='p-4 sm:p-6 md:p-7 my-4 sm:my-6 md:my-8 max-w-[90%] sm:max-w-[45%] md:max-w-[30%]'
    >
      <span className='flex justify-center text-[40px] sm:text-[50px] md:text-[60px]'>
        {item.icon}
      </span>
      <p className='text-[22px] sm:text-[25px] md:text-[22px] p-2'>
        {item.name}
      </p>
      <h1 className='text-[#027BC3] text-[40px] sm:text-[35px] md:text-[40px] font-bold'>
        {item.desc}
      </h1>
    </div>
  ))}
</div>

    </>
  )
}

export default CardList
