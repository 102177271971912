import React from "react";
import QuestionTemplate from "./QuestionTemplate";

const options = [
  { label: "Advertising", value: "Advertising" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Apparel", value: "Apparel" },
  { label: "Auto Repair", value: "Auto Repair" },
  { label: "Auto Sales", value: "Auto Sales" },
  { label: "Banking", value: "Banking" },
  { label: "Biotechnology", value: "Biotechnology" },
  { label: "Business Services", value: "Business Services" },
  { label: "Chiropractor", value: "Chiropractor" },
  { label: "Church", value: "Church" },
  { label: "Cleaning / Janitorial", value: "Cleaning / Janitorial" },
  { label: "Construction", value: "Construction" },
  { label: "Consulting", value: "Consulting" },
  { label: "Convenience Store", value: "Convenience Store" },
  { label: "Dental", value: "Dental" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Education", value: "Education" },
  { label: "Energy", value: "Energy" },
  { label: "Engineering", value: "Engineering" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Finance", value: "Finance" },
  { label: "Gas Station", value: "Gas Station" },
  { label: "Government Contractor", value: "Government Contractor" },
  { label: "Grocery", value: "Grocery" },
  { label: "Home Healthcare", value: "Home Healthcare" },
  { label: "Hotel", value: "Hotel" },
  { label: "HVAC", value: "HVAC" },
  { label: "Insurance", value: "Insurance" },
  { label: "Jewelry", value: "Jewelry" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Medical Practice", value: "Medical Practice" },
  { label: "Nail Salon", value: "Nail Salon" },
  { label: "Not For Profit", value: "Not For Profit" },
  { label: "Optometrist", value: "Optometrist" },
  { label: "Other", value: "Other" },
  { label: "Plumbing", value: "Plumbing" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Recreation", value: "Recreation" },
  { label: "Restaurant and Bar", value: "Restaurant and Bar" },
  { label: "Retail", value: "Retail" },
  { label: "Salon", value: "Salon" },
  { label: "Service", value: "Service" },
  { label: "Shipping", value: "Shipping" },
  { label: "Staffing", value: "Staffing" },
  { label: "Technology", value: "Technology" },
  { label: "Telecommunications", value: "Telecommunications" },
  {
    label: "Transportation and Trucking",
    value: "Transportation and Trucking",
  },
  { label: "Veterinary", value: "Veterinary" },
  { label: "Wholesale / Distributor", value: "Wholesale / Distributor" },
];

export default function IndustryForm({ onSubmit, onGoBack }) {
  return (
    <QuestionTemplate
      title={`What Industry Are You In?`}
      description="Please select the industry that best describes your business. This information helps us provide you with the most relevant solutions."
      onGoBack={onGoBack}
      hasOptionsButtons={true}
    >
      <div className="grid sm:grid-rows-[repeat(25,_minmax(0,_1fr))] gap-4 sm:grid-flow-col">
        {options.map((option, index) => (
          <button
            key={index}
            className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none "
            onClick={() => onSubmit(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </QuestionTemplate>
  );
}
