import React from 'react';
import Header from '../components/adminDashboard/Heder.jsx';
import { questionStatistics } from '../mockData';

const Insights = () => {
    const { question, description, totalResponses, totalAnswered, options } = questionStatistics;

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <Header activeTab="Insights" />
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold mb-2">{question}</h2>
                <p className="text-gray-600 mb-4">{description}</p>
                <p className="text-sm text-gray-500">{`${totalAnswered} of ${totalResponses} visitors answered this question`}</p>

                <div className="mt-4 space-y-2">
                    {options.map((option, index) => (
                        <div key={index} className="flex items-center justify-between">
                            <div className="w-full">
                                <div className="bg-gray-200 h-4 rounded-full overflow-hidden">
                                    <div
                                        className="bg-blue-500 h-full rounded-full"
                                        style={{ width: `${option.percentage}%` }}
                                    ></div>
                                </div>
                            </div>
                            <div className="ml-4 text-sm font-medium text-gray-700">
                                {option.label}
                            </div>
                            <div className="ml-2 text-sm text-gray-500">
                                {option.answers} Answers
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Insights;
