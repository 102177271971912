import React from "react";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

export default function MobileMenu({ isOpen, setIsOpen }) {
  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-[#1f313e] p-8 shadow-lg ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <ul className="flex flex-col gap-4 py-5 px-3 text-white text-lg">
        <li className="hover:text-green-500 cursor-pointer">
          <Link to="/about">About Us</Link>
        </li>
        <li className="hover:text-green-500 cursor-pointer">
          <Link to="/financial">Financial Streamlining</Link>
        </li>
        <li className="hover:text-green-500 cursor-pointer">
          <Link to="/equipment">Equipment Financing</Link>
        </li>
        <li className="hover:text-green-500 cursor-pointer">
          <Link to="/merchant">Merchant Cash Advance</Link>
        </li>
        <li className="hover:text-green-500 cursor-pointer">
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
      <button className="absolute top-5 left-5">
        <IoMdClose
          size={20}
          onClick={() => setIsOpen(false)}
          className="text-[#37ca37] w-6 h-6"
        />
      </button>
    </div>
  );
}
