import React, { useState } from 'react';
import QuestionTemplate from './QuestionTemplate';

const LenderNameForm = ({ onSubmit, onGoBack }) => {
    const [lenderName, setLenderName] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setLenderName(e.target.value);
        if (e.target.value.trim() !== '') {
            setError(''); // Clear the error if input is not empty
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (lenderName.trim() === '') {
            setError('Lender name is required');
        } else {
            onSubmit(lenderName);
        }
    };

    return (
        <QuestionTemplate
            title="What's Your Lender's Name?"
            description="We request the name of your current lender to better understand your financial commitments and to offer tailored solutions that align with your existing obligations."
            onSubmit={handleSubmit}
            onGoBack={onGoBack}
        >
            <div className="mb-4">
                <input
                    type="text"
                    name="lenderName"
                    placeholder="Lender Name"
                    value={lenderName}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
                {error && <span className="text-red-500 text-sm">{error}</span>}
            </div>
        </QuestionTemplate>
    );
};

export default LenderNameForm;
