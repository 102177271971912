import React from "react";
import QuestionTemplate from "./QuestionTemplate";

const LiensJudgmentsForm = ({ businessName, onSubmit, onGoBack }) => {
  return (
    <QuestionTemplate
      title={`Does ${businessName} have Any Open Liens or Judgments?`}
      description="Liens and judgments are legal claims against a borrower's assets or property due to unpaid debts or legal disputes, which can pose financial risk for lenders in cash loan businesses."
      onSubmit={(e) => e.preventDefault()} // Prevent form submission since we're handling clicks directly
      onGoBack={onGoBack}
      hasOptionsButtons
    >
      <div className="flex flex-col gap-4">
        <button
          type="button"
          className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
          onClick={() => onSubmit("Yes")}
        >
          Yes
        </button>
        <button
          type="button"
          className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
          onClick={() => onSubmit("No")}
        >
          No
        </button>
      </div>
    </QuestionTemplate>
  );
};

export default LiensJudgmentsForm;
