import React from 'react'
import manage from '../../assets/images/icon/manage.png'
import invest from '../../assets/images/icon/invest.png'
import over from '../../assets/images/icon/overcome.png'

const data = [
  { id: 1, name: 'Manage day-to-day operations', para: "Ensure seamless business operations by covering vital expenses like payroll, rent, and utilities.", icon: <img src={manage} className="w-[60px] h-[60px]" alt="Manage" /> },
  { id: 2, name: 'Invest in growth opportunities', para: "Capitalize on fresh prospects by allocating funds towards inventory, marketing initiatives, or broadening your range of products or services.", icon: <img src={invest} className="w-[60px] h-[60px]" alt="Invest" /> },
  { id: 3, name: 'Overcome cash flow fluctuations', para: "Sail through seasonal or unforeseen revenue fluctuations, ensuring your business stays on course.", icon: <img src={over} className="w-[60px] h-[60px]" alt="Overcome" /> },
]

const FinancialList = () => {
  return (
    <div className='w-full h-auto '>
      <div className='w-full sm:w-[90%] md:w-[69%] mx-auto'>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {data.map((item) => (
            <div key={item.id} className="p-4 my-6">
              <span className='flex justify-center text-[50px] sm:text-[60px]'>{item.icon}</span>
              <h3 className="text-[20px] sm:text-[22px] md:text-[25px] text-center font-semibold mt-4">{item.name}</h3>
              <p className="text-[16px] sm:text-[17px] text-center mt-2 tracking-tight ">{item.para}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FinancialList
