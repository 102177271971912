import React from 'react';
import QuestionTemplate from './QuestionTemplate'; // Adjust the path as necessary

const PaymentFrequencyForm = ({ onSubmit, onGoBack }) => {
    const options = ['Daily', 'Weekly', 'Bi-Weekly', 'Monthly'];

    return (
        <QuestionTemplate
            title="What's The Frequency Of The Payments?"
            subtitle="We ask about the frequency of payments to tailor our repayment schedule to your cash flow, ensuring it aligns with your business's income cycle and financial stability."
            onNext={(selectedOption) => onSubmit(selectedOption)}
            onBack={onGoBack}
            hasOptionsButtons={true}
        >
            <div className="flex flex-col gap-4">
                {options.map((option) => (
                    <button
                        key={option}
                        className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none"
                        onClick={() => onSubmit(option)}
                    >
                        {option}
                    </button>
                ))}
            </div>
        </QuestionTemplate>
    );
};

export default PaymentFrequencyForm;
