import React from 'react';
import { FaChessPawn } from 'react-icons/fa';
import { FaRegLightbulb } from 'react-icons/fa';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { GiPayMoney } from 'react-icons/gi';

const FinancialListTwo = ({ data }) => {
    return (
        <div className="w-full h-[50vh]">
        <div className="w-[90%] md:w-[75%] lg:w-[69%] mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {data && data.map((item) => (
              <div key={item.id} className="p-4 flex flex-col items-center bg-white rounded-lg shadow-md">
                <span className="text-[40px] sm:text-[50px] lg:text-[65px] text-center mb-4">
                  {item.icon}
                </span>
                <h3 className="text-[20px] sm:text-[22px] lg:text-[25px] px-14 text-center font-semibold mb-2">
                  {item.name}
                </h3>
                <p className="text-[16px] sm:text-[17px] w-[200px] text-center ">
                  {item.para}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
    );
};

export default FinancialListTwo;
